import React, { createContext, useState, useEffect } from 'react';

export const AuthTokenContext = createContext();

export const AuthTokenProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() => {
    const savedAuthTokens = localStorage.getItem('auth_tokens');
    return savedAuthTokens ? JSON.parse(savedAuthTokens) : null;
  });

  useEffect(() => {
    if (authTokens) {
      localStorage.setItem('auth_tokens', JSON.stringify(authTokens));
    }
  }, [authTokens]);

  return (
    <AuthTokenContext.Provider value={{ authTokens, setAuthTokens }}>
      {children}
    </AuthTokenContext.Provider>
  );
};
