import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const SnackbarAlert = ({
  open,
  message,
  severity,
  onClose,
  duration = 5000,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        style={{
          alignContent: "center",
          backgroundColor:
            severity === "success"
              ? "rgba(109, 217, 114, 0.9)" // Green background with 70% opacity for success
              : "rgba(255, 92, 41, 0.9)", // Red background with 90% opacity for error
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: severity === "success" ? "#6DD972" : "#FF5C29",
          color: "#ffffff",
          fontWeight: "bold",
          fontFamily: "Raleway, sans-serif",
          // width: "400px",
          // height: "35px"
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarAlert;
