const API_ENDPOINTS = {
  // AUTH STACK
  SignIn: "https://7w4u8pust6.execute-api.af-south-1.amazonaws.com/dev/signIn",
  RefreshToken:
    "https://7w4u8pust6.execute-api.af-south-1.amazonaws.com/dev/refreshToken",
  SignOut:
    "https://7w4u8pust6.execute-api.af-south-1.amazonaws.com/dev/signOut",
  GetSignInURL:
    "https://7w4u8pust6.execute-api.af-south-1.amazonaws.com/dev/getsigninurl",

  // USER MANAGEMENT STACK
  ListUser: "https://3fih1ggwkl.execute-api.af-south-1.amazonaws.com/dev/list",
  RetrieveUser:
    "https://3fih1ggwkl.execute-api.af-south-1.amazonaws.com/dev/retrieve",
  CreateUser:
    "https://3fih1ggwkl.execute-api.af-south-1.amazonaws.com/dev/create",
  UpdateUser:
    "https://3fih1ggwkl.execute-api.af-south-1.amazonaws.com/dev/update",
  DeleteUser:
    "https://3fih1ggwkl.execute-api.af-south-1.amazonaws.com/dev/delete",

  // TENANT MANGEMENT STACK
  ListTenant:
    "https://511v8d4z27.execute-api.af-south-1.amazonaws.com/Dev/list",
  RetrieveTenant:
    "https://511v8d4z27.execute-api.af-south-1.amazonaws.com/Dev/retrieve",
  CreateTenant:
    "https://511v8d4z27.execute-api.af-south-1.amazonaws.com/Dev/create",
  UpdateTenant:
    "https://511v8d4z27.execute-api.af-south-1.amazonaws.com/Dev/update",
  DeleteTenant:
    "https://511v8d4z27.execute-api.af-south-1.amazonaws.com/Dev/delete",

  // USER COLLECTION STACK
  ListUserCollection:
    "https://djf05d6zdi.execute-api.af-south-1.amazonaws.com/dev/list",
  RetrieveUserCollection:
    "https://djf05d6zdi.execute-api.af-south-1.amazonaws.com/dev/retrieve",
  ShareUserCollection:
    "https://djf05d6zdi.execute-api.af-south-1.amazonaws.com/dev/share",
  DeleteUserCollection:
    "https://djf05d6zdi.execute-api.af-south-1.amazonaws.com/dev/delete",
  UnshareCollection:
    "https://djf05d6zdi.execute-api.af-south-1.amazonaws.com/dev/unshare",

  // COLLECTION STACK
  ListCollections:
    "https://vri8p3460e.execute-api.af-south-1.amazonaws.com/dev/list",
  RetrieveCollection:
    "https://vri8p3460e.execute-api.af-south-1.amazonaws.com/dev/retrieve",
  CreateCollectionAI:
    // "https://dev.ace.aizatroncloud.com/ace/dev/v1/create_collection",
    "https://prod.ace.aizatroncloud.com/ace/prod/v1/create_collection",
  DeleteCollectionAI:
    // "https://dev.ace.aizatroncloud.com/ace/dev/v1/delete_collection",
    "https://prod.ace.aizatroncloud.com/ace/prod/v1/delete_collection",
  UpdateCollection:
    "https://vri8p3460e.execute-api.af-south-1.amazonaws.com/dev/update",

  // CHAT MESSAGE STACK
  Message:
    "https://vri8p3460e.execute-api.af-south-1.amazonaws.com/dev/retrieve",

  // DOCUMENTS STACK
  UploadDocAI:
    // "https://dev.ace.aizatroncloud.com/ace/dev/v1/upload_files",
    "https://prod.ace.aizatroncloud.com/ace/prod/v1/upload_files",
  DeleteDocAI:
    // "https://dev.ace.aizatroncloud.com/ace/dev/v1/delete_files",
    "https://prod.ace.aizatroncloud.com/ace/prod/v1/delete_files?collection_name &document_name &document_id",

  // MY CHATS STACK
  ListChats: "https://aycx0390vh.execute-api.af-south-1.amazonaws.com/dev/list",
  RetrieveChat:
    "https://aycx0390vh.execute-api.af-south-1.amazonaws.com/dev/retrieve",
  CreateChat:
    "https://aycx0390vh.execute-api.af-south-1.amazonaws.com/dev/create",
  GetChatResponse:
    // "https://dev.ace.aizatroncloud.com/ace/dev/v1/get_ace_rag_response?",
    "https://prod.ace.aizatroncloud.com/ace/prod/v1/get_ace_rag_response?",
};

export default API_ENDPOINTS;
