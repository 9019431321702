import React, { useState, useEffect } from "react";
import Navbar from "../components/Navigation";
import {
  Box,
  Typography,
  Button,
  TextField,
  useTheme,
  Paper,
  FormLabel,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import API_ENDPOINTS from "../utilities/APIRoutes";
import SnackbarAlert from "../components/SnackbarAlert";
import Footer from "../components/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    padding: theme.spacing(0.2),
  },
  centerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    fontFamily: "Raleway, sans-serif",
  },
  description: {
    color: "#ffffff",
    fontSize: "1.4rem",
    fontFamily: "Raleway, sans-serif",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem", // font size for smaller screens
    },
  },
  label: {
    color: "white",
    fontFamily: "Raleway, sans-serif",
    fontWeight: "bold",
    fontSize: "0.85rem",
    // marginBottom: theme.spacing(1.5),
    alignSelf: "start",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-around"
    // width: theme.spacing(152),
  },
  cancelButton: {
    backgroundColor: "#D9D9D9",
    color: "#000000",
    borderRadius: "10px",
    border: "none",
    padding: theme.spacing(1, 3),
    width: "8.8em",
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "none",
    marginTop: theme.spacing(2),
    fontFamily: "Raleway, sans-serif",
    "&:hover": {
      color: "#000000",
      backgroundColor: "#D9D9D9",
      border: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem", // font size for smaller screens
    },
  },
  button: {
    backgroundColor: "#FFC629",
    color: "#000000",
    borderRadius: "10px",
    border: "none",
    padding: theme.spacing(1, 3),
    fontSize: "1rem",
    width: "8.8em",
    fontWeight: "bold",
    textTransform: "none",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    fontFamily: "Raleway, sans-serif",
    "&:hover": {
      color: "#000000",
      backgroundColor: "#FFC629",
      border: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem", // font size for smaller screens
    },
  },
  footerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    backgroundColor: "#FFC629",
    padding: theme.spacing(2),
  },
  footer: {
    color: "#747171",
    fontSize: "0.8rem",
    fontFamily: "Raleway, sans-serif",
  },
}));

function EditUser() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [role, setRole] = useState("");
  const UserToken = localStorage.getItem("userToken");
  const AuthToken = localStorage.getItem("authToken");
  const UserID = localStorage.getItem("userId");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchUserDetails = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.RetrieveUser, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "User-Token": UserToken,
          Authorization: AuthToken,
        },
        body: JSON.stringify({ id: UserID }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setUserName(result.message.name);
      setRole(result.message.role);
      console.log("fetch user result:", result);
    } catch (error) {
      console.error("Failed to fetch user details:", error);
      setSnackbarMessage("An error occurred.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!UserToken || !UserID || !role) {
      console.error("Missing user token, user ID, or role");
      setSnackbarMessage("Missing user token, user ID, or role.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await fetch(API_ENDPOINTS.UpdateUser, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "User-Token": UserToken,
          Authorization: AuthToken,
        },
        body: JSON.stringify({ id: UserID, role: role }),
      });

      if (!response.ok) {
        throw new Error("Failed to update user role");
      }

      const result = await response.json();
      console.log("Edit user result:", result);
      if (result.error === false) {
        navigate("/user-management", {
          state: { success: true, message: "User updated successfully" },
        });
      } else if (result.error === true) {
        console.error("Failed to update user role:", result.message);
        setSnackbarMessage("Failed to update user");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error updating user role:", error);
      setSnackbarMessage("An error occurred.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.root}>
      <Navbar />
      <Box className={classes.centerContent}>
        <Paper
          square={false}
          elevation={0}
          variant="elevation"
          style={{
            backgroundColor: "black",
            width: 1300,
            height: "max-content",
            padding: 35,
            borderRadius: 14,
          }}
        >
          <Box
            style={{
              display: "flex",
            }}
          >
            <Typography
              className={classes.description}
              style={{
                alignSelf: "flex-start",
                color: "#ffffff",
                fontSize: "1.2rem",
                fontWeight: "bold",
                fontFamily: "Raleway, sans-serif",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "1rem", // font size for smaller screens
                },
              }}
            >
              Edit user
            </Typography>
          </Box>
          <Box className={classes.formContainer}>
            <form onSubmit={handleSave} className={classes.inputContainer}>
              <FormLabel className={classes.label}>Name</FormLabel>
              <TextField
                placeholder={userName}
                variant="outlined"
                disabled
                aria-label="name"
                style={{
                  backgroundColor: "white",
                  borderRadius: 10,
                  marginBottom: theme.spacing(2),
                  marginTop: theme.spacing(1),
                }}
                InputProps={{
                  style: {
                    color: "black",
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "1rem",
                    fontWeight: "normal",
                  },
                }}
                InputLabelProps={{
                  value: { userName },
                  style: {
                    fontFamily: "Raleway, sans-serif",
                    color: "transparent",
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    borderColor: "none",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.7rem",
                    },
                  },
                }}
              />
              <FormLabel className={classes.label}>Role</FormLabel>
              <TextField
                select
                variant="outlined"
                value={role}
                onChange={handleRoleChange}
                style={{
                  backgroundColor: "white",
                  borderRadius: 10,
                  marginBottom: theme.spacing(2),
                  marginTop: theme.spacing(1),
                }}
                InputLabelProps={{
                  style: {
                    color: "#ffffff",
                    shrink: true,
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "1rem",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.7rem",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    "& .MuiInputBase-input:focus": {
                      outline: "none",
                    },
                    borderRadius: "12px",
                    color: "black",
                    textAlign: "left",
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "1rem",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.7rem",
                    },
                  },
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        backgroundColor: "white",
                      },
                    },
                  },
                }}
              >
                <MenuItem
                  value={"user"}
                  style={{
                    color: "black",
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "1rem",
                    [theme.breakpoints.down("sm")]: { fontSize: "0.7rem" },
                  }}
                >
                  User
                </MenuItem>
                <MenuItem
                  value={"tenant admin"}
                  style={{
                    color: "#000000",
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "1rem",
                    [theme.breakpoints.down("sm")]: { fontSize: "0.7rem" },
                  }}
                >
                  Tenant Administrator
                </MenuItem>
                <MenuItem
                  value={"sys admin"}
                  style={{
                    color: "#000000",
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "1rem",
                    [theme.breakpoints.down("sm")]: { fontSize: "0.7rem" },
                  }}
                >
                  System Administrator
                </MenuItem>
              </TextField>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  className={classes.cancelButton}
                  onClick={() => navigate("/user-management")}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  className={classes.button}
                >
                  {loading ? "Saving . . ." : "Save"}
                </Button>
              </Box>
            </form>
          </Box>
        </Paper>
        <SnackbarAlert
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={handleCloseSnackbar}
        />
      </Box>
      <Footer />
    </Box>
  );
}

export default EditUser;
