import React, { useEffect, useState, useContext } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from "../contexts/UserContext";
import { TenantContext } from "../contexts/TenantContext";
import API_ENDPOINTS from "../utilities/APIRoutes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    flexGrow: 1,
    padding: theme.spacing(0.2),
  },
  centerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    flexGrow: 1,
    fontFamily: "Raleway, sans-serif",
    marginTop: theme.spacing(-8),
  },
  title: {
    color: "#ffffff",
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
    fontFamily: "Raleway, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem", // font size for smaller screens
    },
  },
  text: {
    marginBottom: theme.spacing(4),
    fontSize: "1rem",
    fontFamily: "Raleway, sans-serif",
    color: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
  successIcon: {
    width: "30em",
    height: "30em",
    color: "#FFC629",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem", // font size for smaller screens
    },
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    width: theme.spacing(40),
    marginBottom: theme.spacing(2),
    alignItems: "flex-center",
  },
}));

function LoadSignIn() {
  const classes = useStyles();
  const [paramValue, setParamValue] = useState(null);
  const { userInfo, setUserInfo } = useContext(UserContext);
  const { tenantInfo, setTenantInfo } = useContext(TenantContext);
  // const { authInfo, setAuthInfo } = useContext(AuthTokenContext);
  //   const [code, setCode] = useState("");
  //   const [email, setEmail] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const value = queryParams.get("code");
      setParamValue(value);

      const updateTenantInfo = (code, email) => {
        setTenantInfo({ code: code, email: email });
      };

      // SIGN IN API CALL
      try {
        const response = await fetch(API_ENDPOINTS.SignIn, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            code: value,
            email: userInfo.email,
          }),
        });

        const result = await response.json();

        if (!response.ok || result.error) {
          throw new Error("Network response was not ok");
        }

        localStorage.setItem("userToken", result.message.userToken);
        localStorage.setItem("authToken", result.message.authorizationToken);
        localStorage.setItem("refreshToken", result.message.refreshToken);
        localStorage.setItem("email", result.message.user.email);
        localStorage.setItem("name", result.message.user.name);
        localStorage.setItem("department", result.message.user.department);
        localStorage.setItem("user_id", result.message.user.id);
        localStorage.setItem("role", result.message.user.role);
        localStorage.setItem("tokenLimit", result.message.user.tokenLimit);
        localStorage.setItem("tokenUsage", result.message.user.tokenUsage);
        updateTenantInfo(value, userInfo.email);

        if (result.message.user.role === "sys admin") {
          window.location.href = "/tenant-management";
        } else if (result.message.user.role === "tenant admin") {
          window.location.href = "/user-management";
        } else if (result.message.user.role === "user") {
          window.location.href = "/main-chat";
        } //else {
        //window.location.href = "/not-found"
        //}
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);

  return (
    <Box className={classes.root}>
      <CircularProgress size={90} />
    </Box>
  );
}

export default LoadSignIn;
