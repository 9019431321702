import React, { useEffect, useRef } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import MainChat from "../pages/MainChat";
import CollectionManagement from "../pages/CollectionManagement";
import Profile from "../pages/Profile";
import TenantManagement from "../pages/TenantManagement";
import Unauthorized from "../pages/Unauthorized";
import Login from "../pages/Login";
import UserManagement from "../pages/UserManagement";
import AddTenant from "../pages/AddTenant";
import AddUser from "../pages/AddUser";
import EditUser from "../pages/EditUser";
import GiveAdminRights from "../pages/GiveAdminRight";
import LoadSignIn from "../pages/LoadingSignin";
import EditTenant from "../pages/EditTenant";
import TermsAndConditions from "../pages/Terms&Conditions";
import PrivateRoute from "../components/PrivateRouter";
import API_ENDPOINTS from "../utilities/APIRoutes";

function Container() {
  const isAuthenticated =
    !!localStorage.getItem("authToken") && !!localStorage.getItem("userToken");
  const userRole = localStorage.getItem("role");
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef(); // to store the refresh interval ID
  const signOutTimerRef = useRef(null); // to store the sign-out timer

  // Token refreshing function using fetch
  useEffect(() => {
    const refreshToken = async () => {
      try {
        const refreshToken = localStorage.getItem("refreshToken");
        const userToken = localStorage.getItem("userToken");

        // Only attempt refresh if the tokens exist
        if (refreshToken && userToken) {
          const response = await fetch(API_ENDPOINTS.RefreshToken, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              RefreshToken: refreshToken,
              "User-Token": userToken,
            },
          });

          if (response.ok) {
            const data = await response.json();
            // Assuming response has the same structure with tokens
            if (!data.error) {
              localStorage.setItem(
                "authToken",
                data.message.authorizationToken
              );
              localStorage.setItem("userToken", data.message.userToken);
              localStorage.setItem("refreshToken", data.message.refreshToken);
            } else {
              // Handle any error response from the server
              console.error("Error refreshing tokens:", data.message);
            }
          } else {
            console.error(
              "Failed to refresh tokens, response status:",
              response.status
            );
          }
        }
      } catch (error) {
        console.error("Error during token refresh:", error);
      }
    };

    // Call refreshToken immediately and set an interval to refresh every 2 hours (7200 seconds)
    refreshToken();
    const interval = setInterval(refreshToken, 7200 * 1000); // 2 hours
    ref.current = interval;

    // Clean up the interval when component unmounts
    return () => clearInterval(ref.current);
  }, []);

  // Automatic sign-out function
  const autoSignOut = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const authToken = localStorage.getItem("authToken");
      const refreshToken = localStorage.getItem("refreshToken");

      const response = await fetch(API_ENDPOINTS.SignOut, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "User-Token": userToken,
          Authorization: authToken,
          "Refresh-Token": refreshToken,
        },
      });

      if (response.ok) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("userToken");
        localStorage.removeItem("refreshToken");
        localStorage.clear();
        const result = await response.json();
        // const microsoftLogoutUrl = `https://${result.logoutUrl}`;
        const microsoftLogoutUrl = result.logoutUrl.startsWith("https://")
          ? result.logoutUrl
          : `https://${result.logoutUrl}`;
        window.location.href = microsoftLogoutUrl;
      } else {
        console.error("Failed to sign out user");
      }
    } catch (error) {
      console.error("Failed to sign out:", error);
    }
    console.log("Auto sign-out triggered");
  };

  // Reset timer on user activity
  useEffect(() => {
    const autoSignOutDuration = 40 * 60 * 1000; // 40 min in milliseconds

    const checkForSignOutOnLoad = () => {
      const lastActivityTime = localStorage.getItem("lastActivityTime");
      if (
        lastActivityTime &&
        Date.now() - parseInt(lastActivityTime, 10) >= autoSignOutDuration
      ) {
        autoSignOut(); // Sign out immediately if time has exceeded
      } else {
        // Initialize the sign-out timer
        resetSignOutTimer();
      }
    };

    const resetSignOutTimer = () => {
      clearTimeout(signOutTimerRef.current);
      signOutTimerRef.current = setTimeout(autoSignOut, autoSignOutDuration);
      localStorage.setItem("lastActivityTime", Date.now().toString()); // Update last activity time in localStorage
    };

    // Check for sign-out on first load
    checkForSignOutOnLoad();

    // Event listeners to reset the timer on activity
    window.addEventListener("mousemove", resetSignOutTimer);
    window.addEventListener("keypress", resetSignOutTimer);
    window.addEventListener("scroll", resetSignOutTimer);

    // Backup check using setInterval every minute
    const interval = setInterval(() => {
      const storedTime = localStorage.getItem("lastActivityTime");
      if (
        storedTime &&
        Date.now() - parseInt(storedTime, 10) >= autoSignOutDuration
      ) {
        autoSignOut(); // Sign out if the duration has passed since the last activity
      }
    }, 60000); // Check every 1 minute

    // Cleanup on component unmount
    return () => {
      clearTimeout(signOutTimerRef.current);
      clearInterval(interval);
      window.removeEventListener("mousemove", resetSignOutTimer);
      window.removeEventListener("keypress", resetSignOutTimer);
      window.removeEventListener("scroll", resetSignOutTimer);
    };
  }, []);

  // Redirect users from the login page to the app if already authenticated
  useEffect(() => {
    if (
      isAuthenticated &&
      (location.pathname === "/" || location.pathname === "/login") && userRole
    ) {
      navigate("/main-chat");
    }
  }, [isAuthenticated, navigate, location.pathname, userRole]);

  // Redirect users to login page if not authenticated
  // useEffect(() => {
  //   if (!isAuthenticated && location.pathname !== "/") {
  //     navigate("/");
  //   }
  // }, [isAuthenticated, navigate, location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/loading-signin" element={<LoadSignIn />} />
      <Route
        path="/"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            allowedRoles={["user", "tenant admin", "sys admin"]}
            userRole={userRole}
          />
        }
      >
        <Route path="main-chat" element={<MainChat />} />
        <Route path="collections" element={<CollectionManagement />} />
        <Route path="profile" element={<Profile />} />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      </Route>

      <Route
        path="/tenant-management"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            allowedRoles={["sys admin"]}
            userRole={userRole}
          />
        }
      >
        <Route path="" element={<TenantManagement />} />
        <Route path="edit-tenant" element={<EditTenant />} />
        <Route path="add-tenant" element={<AddTenant />} />
      </Route>

      <Route
        path="/user-management"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            allowedRoles={["sys admin", "tenant admin"]}
            userRole={userRole}
          />
        }
      >
        <Route path="" element={<UserManagement />} />
        <Route path="edit-user" element={<EditUser />} />
        <Route path="add-user" element={<AddUser />} />
      </Route>

      <Route path="/unauthorized" element={<Unauthorized />} />
    </Routes>
  );
}

export default Container;
