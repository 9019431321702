import React from "react";
import Navbar from "../components/Navigation";
import { Box, Button, TextField, FormLabel } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@mui/material";
import Footer from "../components/Footer";
import API_ENDPOINTS from "../utilities/APIRoutes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignContent: "center",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    padding: theme.spacing(0.2),
  },
  label: {
    color: "white",
    fontFamily: "Raleway, sans-serif",
    fontWeight: "bold",
    fontSize: "0.9rem",
    marginBottom: theme.spacing(1.5),
    alignSelf: "start",
  },
  userInfoContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "8px",
    margin: "auto",
    width: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: "23%",
  },
  buttonReset: {
    backgroundColor: "#FFC629",
    color: "#000000",
    borderRadius: "10px",
    padding: theme.spacing(1, 3),
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "none",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontFamily: "Raleway, sans-serif",
    "&:hover": {
      backgroundColor: "#FFC629",
      color: "#FFFFFF",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem", // font size for smaller screens
    },
    // width: "15em"
  },
  buttonLogout: {
    backgroundColor: "#F5F5F5",
    color: "#000000",
    borderRadius: "10px",
    padding: theme.spacing(1, 3),
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "none",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontFamily: "Raleway, sans-serif",
    "&:hover": {
      backgroundColor: "#FFC629",
      color: "#000000",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem", // font size for smaller screens
    },
  },
}));

function Profile() {
  const classes = useStyles();
  const navigate = useNavigate();

  const user = {
    name: localStorage.getItem("name"),
    email: localStorage.getItem("email"),
    department: localStorage.getItem("department"),
  };

  const signOut = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const authToken = localStorage.getItem("authToken");
      const refreshToken = localStorage.getItem("refreshToken");

      const response = await fetch(API_ENDPOINTS.SignOut, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "User-Token": userToken,
          Authorization: authToken,
          "Refresh-Token": refreshToken,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();

      console.log("sign out response", result);
      if (result.error === false || result.message === "Sign out successful") {
        localStorage.removeItem("authToken");
        localStorage.removeItem("userToken");
        localStorage.removeItem("refreshToken");
        localStorage.clear();

        // const microsoftLogoutUrl = `https://login.microsoftonline.com/7bef8111-7879-4a95-9cb6-057e40a92aaa/saml2/logout`;
        // window.location.href = microsoftLogoutUrl;

        // setTimeout(() => {
        //   window.location.href = `${window.location.origin}http://localhost:3000/login`;
        // }, 3000);
        const microsoftLogoutUrl = `https://${result.logoutUrl}`;
        window.location.href = microsoftLogoutUrl;
      } else if (result.error === true) {
        console.error("Failed to sign out user:", result.message);
      }
    } catch (error) {
      console.error("Failed to sign out:", error);
    }
  };

  return (
    <Box className={classes.root}>
      <Navbar />
      <Box className={classes.userInfoContainer}>
        <Box className={classes.column}>
          <FormLabel className={classes.label}>Name</FormLabel>
          <TextField
            value={user.name}
            variant="outlined"
            disabled
            style={{
              backgroundColor: "white",
              borderRadius: 10,
              marginBottom: 40,
            }}
            InputProps={{
              style: {
                color: "#747171",
                fontFamily: "Raleway, sans-serif",
                fontSize: "0.95rem",
                // fontWeight: "bold",
              },
            }}
          />
          <FormLabel className={classes.label}>Email</FormLabel>
          <TextField
            value={user.email}
            variant="outlined"
            disabled
            style={{
              backgroundColor: "white",
              borderRadius: 10,
              marginBottom: 40,
            }}
            InputProps={{
              style: {
                color: "#747171",
                fontFamily: "Raleway, sans-serif",
                fontSize: "0.95rem",
                // fontWeight: "bold",
              },
            }}
          />
          <FormLabel className={classes.label}>Department</FormLabel>
          <TextField
            value={user.department}
            variant="outlined"
            disabled
            style={{
              backgroundColor: "white",
              borderRadius: 10,
              marginBottom: 20,
            }}
            InputProps={{
              style: {
                color: "#747171",
                fontFamily: "Raleway, sans-serif",
                fontSize: "0.95rem",
                // fontWeight: "bold",
              },
            }}
          />
        </Box>
      </Box>
      <Divider
        variant="middle"
        sx={{
          display: "flex",
          py: 0,
          width: "75em",
          //   maxWidth: "10em",
          borderRadius: 10,
          border: "0.5px solid",
          borderColor: "divider",
          backgroundColor: "lightGrey",
          mt: 5,
          alignSelf: "center",
        }}
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          padding: "2em",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          sx={{ mt: 3 }}
          className={classes.buttonLogout}
          onClick={signOut}
        >
          Logout
        </Button>
      </Box>
      <Footer />
    </Box>
  );
}

export default Profile;
