import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import "./App.css";
import Container from "./components/Container";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider>
          <Container />
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
