import React from "react";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    padding: theme.spacing(0.2),
  },
  label: {
    color: "white",
    fontFamily: "Raleway, sans-serif",
    fontWeight: "bold",
    fontSize: "5rem",
    marginBottom: theme.spacing(1.5),
  },

}));

function Unauthorized() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <h1 className={classes.label}>Unauthorized</h1>
      {/* <RemoveCircleOutlineRoundedIcon
        style={{
          color: "#FFC629",
          borderRadius: 8,
          margin: "0.2rem",
          width: "10%",
          height:"10%"
        }}
      /> */}
      <p style={{color: "#747171", fontSize: "1.3rem"}}>You do not have permission to view this page.</p>
      <Link style={{color:"#FFC629", textDecoration: "none"}} to="/main-chat">Go to Home</Link>
    </Box>
  );
}

export default Unauthorized;
