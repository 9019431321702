import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  useTheme,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Navbar from "../components/Navigation";

const listDummyUsers = [
  { id: 1, name: "John Doe" },
  { id: 2, name: "Jane Smith" },
  { id: 3, name: "Alice Johnson" },
  { id: 4, name: "Jane Doe" },
  { id: 5, name: "Maxine Cloete" },
  { id: 6, name: "Emily May" },
  { id: 7, name: "Mike Dean" },
  { id: 8, name: "Decan Doe" },
  { id: 9, name: "Alex Doe" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    padding: theme.spacing(0.2),
  },
  description: {
    alignSelf: "flex-start",
    color: "#ffffff",
    fontSize: "1.2rem",
    fontWeight: "bold",
    fontFamily: "Raleway, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem", // font size for smaller screens
    },
  },
  centerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    fontFamily: "Raleway, sans-serif",
    padding: theme.spacing(10),
  },
  userList: {
    // padding: "1px 38px",
    marginTop: theme.spacing(2),
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#303030",
    color: "#ffffff",
    marginBottom: "1em",
    borderRadius: 8,
  },
  listItemText: {
    fontFamily: "Raleway",
    // paddingTop: theme.spacing(-0.5),
    // paddingBottom: theme.spacing(-0.5)
  },
  cancelButton: {
    backgroundColor: "#D9D9D9",
    color: "#000000",
    borderRadius: "10px",
    border: "none",
    padding: theme.spacing(1, 3),
    width: "8.5em",
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "none",
    marginTop: theme.spacing(2),
    fontFamily: "Raleway, sans-serif",
    "&:hover": {
      color: "#000000",
      backgroundColor: "#D9D9D9",
      border: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem", // font size for smaller screens
    },
  },
  footerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    backgroundColor: "#FFC629",
    padding: theme.spacing(2),
  },
  footer: {
    color: "#747171",
    fontSize: "0.8rem",
  },
}));

function GiveAdminRights() {
  const classes = useStyles();
  const theme = useTheme();

  const [users, setUsers] = useState(1);
  const pageSize = 4;

  const handleUsersChange = (event, newPage) => {
    setUsers(newPage);
  };

  const publicDisplay = listDummyUsers.slice(
    (users - 1) * pageSize,
    users * pageSize
  );

  return (
    <Box className={classes.root}>
      <Navbar />
      <Box className={classes.centerContent}>
        <Paper
          square={false}
          elevation={0}
          variant="elevation"
          style={{
            backgroundColor: "black",
            width: 1300,
            height: "max-content",
            padding: 35,
            // marginBottom: theme.spacing(4),
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              //   padding: 35,
            }}
          >
            <Typography
              className={classes.description}
              sx={{ fontFamily: "Raleway, sans-serif" }}
            >
              Admin rights
            </Typography>
            <Box className={classes.userList}>
              <List>
                {publicDisplay.map((user) => (
                  <ListItem
                    key={user.id}
                    className={classes.listItem}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <ListItemText
                      primary={user.name}
                      className={classes.listItemText}
                    />
                    <Box>
                      <FormControlLabel
                        control={
                          <Switch size="small" style={{ color: "#FFC629" }} />
                        }
                        label="Admin"
                      />
                    </Box>
                  </ListItem>
                ))}
              </List>
              <Box
                onClick={() => (window.location.href = "/tenant-management")}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: theme.spacing(2),
                }}
              >
                <Button className={classes.cancelButton}>Cancel</Button>
              </Box>
              {listDummyUsers.length > pageSize && (
                <Pagination
                  sx={{
                    display: "inline-flex",
                    backgroundColor: "#FFC629",
                    borderRadius: 1,
                    width: "fit-content",
                  }}
                  size="small"
                  shape="rounded"
                  variant="outined"
                  count={Math.ceil(listDummyUsers.length / pageSize)}
                  page={users}
                  onChange={handleUsersChange}
                />
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box className={classes.footerContainer}>
        <Typography variant="body1" className={classes.footer}>
          Copyright &copy; ACE 2024 | Powered by AIZATRON
        </Typography>
      </Box>
    </Box>
  );
}

export default GiveAdminRights;
