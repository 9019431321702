import React, { useState, useEffect, useContext } from "react";
import Navbar from "../components/Navigation";
import {
  Box,
  Typography,
  Button,
  TextField,
  useTheme,
  Paper,
  FormLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AuthTokenContext } from "../contexts/AuthTokenContext";
import { useNavigate } from "react-router-dom";
import API_ENDPOINTS from "../utilities/APIRoutes";
import SnackbarAlert from "../components/SnackbarAlert";
import Footer from "../components/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    padding: theme.spacing(0.2),
  },
  centerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    fontFamily: "Raleway, sans-serif",
  },
  description: {
    color: "#ffffff",
    fontSize: "1.4rem",
    fontFamily: "Raleway, sans-serif",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem", // font size for smaller screens
    },
  },
  label: {
    color: "white",
    fontFamily: "Raleway, sans-serif",
    fontWeight: "bold",
    fontSize: "0.85rem",
    // marginBottom: theme.spacing(1.5),
    alignSelf: "start",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
  },
  cancelButton: {
    backgroundColor: "#D9D9D9",
    color: "#000000",
    borderRadius: "10px",
    border: "none",
    padding: theme.spacing(1, 3),
    width: "8.8em",
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "none",
    marginTop: theme.spacing(2),
    fontFamily: "Raleway, sans-serif",
    "&:hover": {
      color: "#000000",
      backgroundColor: "#D9D9D9",
      border: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem", // font size for smaller screens
    },
  },
  button: {
    backgroundColor: "#FFC629",
    color: "#000000",
    borderRadius: "10px",
    border: "none",
    padding: theme.spacing(1, 3),
    fontSize: "1rem",
    width: "8.8em",
    fontWeight: "bold",
    textTransform: "none",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    fontFamily: "Raleway, sans-serif",
    "&:hover": {
      color: "#000000",
      backgroundColor: "#FFC629",
      border: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
  footerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    backgroundColor: "#FFC629",
    padding: theme.spacing(2),
  },
  footer: {
    color: "#747171",
    fontSize: "0.8rem",
    fontFamily: "Raleway, sans-serif",
  },
}));

function EditTenant() {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { authInfo } = useContext(AuthTokenContext);
  const navigate = useNavigate();
  const [tenantName, setTenantName] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const UserToken = localStorage.getItem("userToken");
  const AuthToken = localStorage.getItem("authToken");
  const TenantID = localStorage.getItem("tenantId");

  // Fetch tenant details when the component mounts
  useEffect(() => {
    fetchTenantDetails();
  }, []);

  const fetchTenantDetails = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.RetrieveTenant, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "User-Token": UserToken,
          Authorization: AuthToken,
        },
        body: JSON.stringify({ id: TenantID }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setTenantName(result.message[0].tenantName);
      console.log("fetch tenant result:", result);
    } catch (error) {
      setSnackbarMessage("An error occurred.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error("Failed to fetch tenant details:", error);
    }
  };

  const handleTenantNameChange = (e) => {
    setTenantName(e.target.value);
  };

  const handleEditTenant = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const response = await fetch(API_ENDPOINTS.UpdateTenant, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "User-Token": UserToken,
          Authorization: AuthToken,
        },
        body: JSON.stringify({
          tenantID: TenantID,
          tenantName,
        }),
      });

      const result = await response.json();
      if (result.error === false) {
        navigate("/tenant-management", {
          state: { success: true, message: "Tenant updated successfully!" },
        });
      } else if (result.error === true) {
        setSnackbarMessage("Failed to update tenant.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("An error occurred.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.root}>
      <Navbar />
      <Box className={classes.centerContent}>
        <Paper
          square={false}
          elevation={0}
          variant="elevation"
          style={{
            backgroundColor: "black",
            width: 1300,
            height: "max-content",
            padding: 35,
            borderRadius: 14,
          }}
        >
          <Box
            style={{
              display: "flex",
            }}
          >
            <Typography
              className={classes.description}
              style={{
                alignSelf: "flex-start",
                color: "#ffffff",
                fontSize: "1.2rem",
                fontWeight: "bold",
                fontFamily: "Raleway, sans-serif",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "1rem",
                },
              }}
            >
              Edit tenant
            </Typography>
          </Box>
          <Box className={classes.formContainer}>
            <form
              onSubmit={handleEditTenant}
              className={classes.inputContainer}
            >
              <FormLabel className={classes.label}>Tenant Name</FormLabel>
              <TextField
                // value={tenantName}
                placeholder={tenantName}
                variant="outlined"
                required
                aria-label="name"
                onChange={handleTenantNameChange}
                style={{
                  backgroundColor: "white",
                  borderRadius: 10,
                  marginBottom: theme.spacing(2),
                  marginTop: theme.spacing(1),
                }}
                InputProps={{
                  style: {
                    color: "black",
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "1rem",
                    fontWeight: "normal",
                  },
                }}
                InputLabelProps={{
                  value: { tenantName },
                  style: {
                    fontFamily: "Raleway, sans-serif",
                    color: "transparent",
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    borderColor: "none",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.7rem",
                    },
                  },
                }}
              />
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  className={classes.cancelButton}
                  onClick={() => navigate("/tenant-management")}
                >
                  Cancel
                </Button>
                <Button
                  // disabled={loading}
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  className={classes.button}
                >
                  {loading ? "Saving . . ." : "Save"}
                </Button>
              </Box>
            </form>
          </Box>
        </Paper>
        <SnackbarAlert
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={handleCloseSnackbar}
        />
      </Box>
      <Footer />
    </Box>
  );
}

export default EditTenant;
