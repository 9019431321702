import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  MenuItem,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../components/Navigation";
import API_ENDPOINTS from "../utilities/APIRoutes";
import SnackbarAlert from "../components/SnackbarAlert";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    padding: theme.spacing(0.2),
  },
  title: {
    color: "#ffffff",
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
    fontFamily: "Raleway, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem", // font size for smaller screens
    },
  },
  centerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    fontFamily: "Raleway, sans-serif",
  },
  label: {
    color: "white",
    fontFamily: "Raleway, sans-serif",
    fontWeight: "normal",
    fontSize: "0.85rem",
    // marginBottom: theme.spacing(1.5),
    alignSelf: "start",
  },
  description: {
    color: "#ffffff",
    fontSize: "1.4rem",
    fontFamily: "Raleway, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem", // font size for smaller screens
    },
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    width: theme.spacing(152),
    marginBottom: theme.spacing(2),
    alignItems: "flex-center",
  },
  formContainer: {
    padding: "1px 38px",
    marginTop: "1em",
  },
  cancelButton: {
    backgroundColor: "#D9D9D9",
    color: "#000000",
    borderRadius: "10px",
    border: "none",
    padding: theme.spacing(1, 3),
    width: "8.8em",
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "none",
    marginTop: theme.spacing(2),
    fontFamily: "Raleway, sans-serif",
    "&:hover": {
      color: "#000000",
      backgroundColor: "#D9D9D9",
      border: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem", // font size for smaller screens
    },
  },
  button: {
    backgroundColor: "#FFC629",
    color: "#000000",
    borderRadius: "10px",
    border: "none",
    padding: theme.spacing(1, 3),
    fontSize: "1rem",
    width: "8.8em",
    fontWeight: "bold",
    textTransform: "none",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    fontFamily: "Raleway, sans-serif",
    "&:hover": {
      color: "#000000",
      backgroundColor: "#FFC629",
      border: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem", // font size for smaller screens
    },
  },
  footerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    backgroundColor: "#FFC629",
    padding: theme.spacing(2),
  },
  footer: {
    color: "#747171",
    fontSize: "0.8rem",
    fontFamily: "Raleway, sans-serif",
  },
}));

function UserManagement() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const UserToken = localStorage.getItem("userToken");
  const AuthToken = localStorage.getItem("authToken");

  const [loading, setLoading] = useState(false);
  const [name, setUserName] = useState("");
  const [user_email, setUserEmail] = useState("");
  const [role, setUserRole] = useState("");
  const [department, setUserDepartment] = useState("");

  const handleUserNameChange = (e) => setUserName(e.target.value);
  const handleUserEmailChange = (e) => setUserEmail(e.target.value);
  const handleUSerRoleChnage = (e) => setUserRole(e.target.value);
  const handleUserDepartmentChange = (e) => setUserDepartment(e.target.value);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleAddUser = async (event) => {
    event.preventDefault();
    setLoading(true);
    const newUser = {
      name,
      user_email,
      role,
      department,
    };

    try {
      const response = await fetch(API_ENDPOINTS.CreateUser, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "User-Token": UserToken,
          Authorization: AuthToken,
        },
        body: JSON.stringify(newUser),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Create user response:", result);

      if (result.error === false) {
        navigate("/user-management", {
          state: { success: true, message: "User created successfully" },
        });
      } else if (result.error === true) {
        console.error("Failed to create user:", result.message);
        setSnackbarMessage("Failed to create user!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Failed to create user:", error);
      setSnackbarMessage("An error occurred.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.root}>
      <Navbar />
      <Box className={classes.centerContent}>
        <Paper
          square={false}
          elevation={0}
          variant="elevation"
          style={{ backgroundColor: "black", width: 1300, height: "max-content", borderRadius: 14 }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: 35,
              alignItems: "center",
            }}
          >
            <Typography
              className={classes.description}
              sx={{ fontFamily: "Raleway, sans-serif" }}
            >
              Create user
            </Typography>
          </Box>
          <Box className={classes.formContainer}>
            <form onSubmit={handleAddUser} className={classes.inputContainer}>
              <TextField
                label="Name"
                variant="outlined"
                margin="normal"
                fullWidth
                value={name}
                onChange={handleUserNameChange}
                required
                aria-label="name"
                style={{ backgroundColor: "white", borderRadius: 10 }}
                InputLabelProps={{
                  style: {
                    color: "grey",
                    shrink: true,
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "1rem",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.7rem",
                    },
                  },
                }}
              />
              <TextField
                label="Email"
                variant="outlined"
                margin="normal"
                fullWidth
                value={user_email}
                onChange={handleUserEmailChange}
                required
                aria-label="email"
                style={{ backgroundColor: "white", borderRadius: 10 }}
                InputLabelProps={{
                  style: {
                    color: "grey",
                    shrink: true,
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "1rem",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.7rem",
                    },
                  },
                }}
              />
              <TextField
                label="Role"
                placeholder="Role"
                variant="outlined"
                margin="normal"
                select
                fullWidth
                value={role}
                onChange={handleUSerRoleChnage}
                required
                aria-label="role"
                style={{ backgroundColor: "white", borderRadius: 10 }}
                InputLabelProps={{
                  style: {
                    color: "grey",
                    shrink: true,
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "1rem",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.7rem",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    "& .MuiInputBase-input:focus": {
                      outline: "none",
                    },
                    borderRadius: "12px",
                    color: "black",
                    textAlign: "left",
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "1rem",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.7rem",
                    },
                  },
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        backgroundColor: "white",
                      },
                    },
                  },
                }}
              >
                <MenuItem
                  value={"user"}
                  style={{
                    color: "black",
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "1rem",
                    [theme.breakpoints.down("sm")]: { fontSize: "0.7rem" },
                  }}
                >
                  User
                </MenuItem>
                <MenuItem
                  value={"tenant admin"}
                  style={{
                    color: "#000000",
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "1rem",
                    [theme.breakpoints.down("sm")]: { fontSize: "0.7rem" },
                  }}
                >
                  Tenant Administrator
                </MenuItem>
                <MenuItem
                  value={"sys admin"}
                  style={{
                    color: "#000000",
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "1rem",
                    [theme.breakpoints.down("sm")]: { fontSize: "0.7rem" },
                  }}
                >
                  System Administrator
                </MenuItem>
              </TextField>
              <TextField
                label="Department"
                variant="outlined"
                margin="normal"
                fullWidth
                value={department}
                onChange={handleUserDepartmentChange}
                required
                aria-label="department"
                style={{ backgroundColor: "white", borderRadius: 10 }}
                InputLabelProps={{
                  style: {
                    color: "grey",
                    shrink: true,
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "1rem",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.7rem",
                    },
                  },
                }}
              />
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <Button
                  to="/"
                  variant="outlined"
                  color="primary"
                  type="submit"
                  size="large"
                  style={{ mt: 3, textDecoration: "none", padding: "0.7em" }}
                  className={classes.cancelButton}
                  onClick={() => (window.location.href = "/user-management")}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  // sx={{ mt: 3 }}
                  className={classes.button}
                >
                  {loading ? "Creating . . ." : "Create"}
                </Button>
              </Box>
            </form>
          </Box>
        </Paper>
        <SnackbarAlert
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={handleCloseSnackbar}
        />
      </Box>
      <Footer />
    </Box>
  );
}

export default UserManagement;
