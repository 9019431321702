import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  useTheme,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../components/Navigation";
import API_ENDPOINTS from "../utilities/APIRoutes";
import SnackbarAlert from "../components/SnackbarAlert";
import Footer from "../components/Footer";
import { Label } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    padding: theme.spacing(0.2),
  },
  centerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    fontFamily: "Raleway, sans-serif",
    marginTop: theme.spacing(13),
    padding: theme.spacing(5)
  },
  description: {
    color: "#ffffff",
    fontSize: "1.4rem",
    fontFamily: "Raleway, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem", // font size for smaller screens
    },
  },
  subHeading: {
    color: "#ffffff",
    fontSize: "1rem",
    fontWeight: "lighter",
    textAlign: "start",
    fontFamily: "Raleway, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem", // font size for smaller screens
    },
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    width: theme.spacing(152),
    marginBottom: theme.spacing(2),
    alignItems: "flex-center",
  },
  formContainer: {
    padding: "1px 38px",
    marginTop: "1em",
  },
  cancelButton: {
    backgroundColor: "#D9D9D9",
    color: "#000000",
    borderRadius: "10px",
    border: "none",
    padding: theme.spacing(1, 3),
    width: "8.8em",
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "none",
    marginTop: theme.spacing(2),
    fontFamily: "Raleway, sans-serif",
    "&:hover": {
      color: "#000000",
      backgroundColor: "#D9D9D9",
      border: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem", // font size for smaller screens
    },
  },
  button: {
    backgroundColor: "#FFC629",
    color: "#000000",
    borderRadius: "10px",
    border: "none",
    padding: theme.spacing(1, 3),
    width: "8.8em",
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "none",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    fontFamily: "Raleway, sans-serif",
    "&:hover": {
      color: "#000000",
      backgroundColor: "#FFC629",
      border: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem", // font size for smaller screens
    },
  },
  footerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    backgroundColor: "#FFC629",
    padding: theme.spacing(2),
  },
  footer: {
    color: "#747171",
    fontSize: "0.8rem",
    fontFamily: "Raleway, sans-serif",
  },
}));

function AddTenant() {
  const UserToken = localStorage.getItem("userToken");
  const AuthToken = localStorage.getItem("authToken");
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [tenantName, setTenantName] = useState("");
  const [tenantDomain, setTenantDomain] = useState("");
  const [configData, setConfigData] = useState("");
  const [vectorDbUrl, setVectorDbUrl] = useState("");
  const [department, setDepartment] = useState("");
  const [user_email, setUserEmail] = useState("");
  const [name, setName] = useState("");

  const handleTenantNameChange = (e) => setTenantName(e.target.value);
  const handleTenantDomainChange = (e) => setTenantDomain(e.target.value);
  const handleConfigDataChange = (e) => setConfigData(e.target.value);
  const handleVectorDbUrlChange = (e) => setVectorDbUrl(e.target.value);
  const handleDepartmentChnage = (e) => setDepartment(e.target.value);
  const handleUserEmailChange = (e) => setUserEmail(e.target.value);
  const handleNameChange = (e) => setName(e.target.value);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleAddTenant = async (event) => {
    event.preventDefault();
    setLoading(true);
    const newTenant = {
      tenantName,
      tenantDomain,
      configData,
      vectordbUrl: vectorDbUrl,
      department,
      user_email,
      name
    };

    try {
      const response = await fetch(API_ENDPOINTS.CreateTenant, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "User-Token": UserToken,
          Authorization: AuthToken,
        },
        body: JSON.stringify(newTenant),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (
        result.errorCode === 200 &&
        result.message === "Tenant created successfully"
      ) {
        // Redirect to tenant management page or show a success message
        navigate("/tenant-management", {
          state: { success: true, message: "Tenant created successfully!" },
        });
      } else {
        setSnackbarMessage(result.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        console.error("Failed to create tenant:", result.message);
      }
    } catch (error) {
      setSnackbarMessage("An error occurred.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.root}>
      <Navbar />
      <Box className={classes.centerContent}>
        <Paper
          square={false}
          elevation={0}
          variant="elevation"
          style={{
            backgroundColor: "black",
            width: 1300,
            height: "max-content",
            borderRadius: 14,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: 35,
              alignItems: "center",
            }}
          >
            <Typography
              className={classes.description}
              sx={{ fontFamily: "Raleway, sans-serif" }}
            >
              Create tenant
            </Typography>
          </Box>
          <Box className={classes.formContainer}>
            <form onSubmit={handleAddTenant} className={classes.inputContainer}>
              <Typography className={classes.subHeading}>
                Tenant Information
              </Typography>
              <TextField
                label="Tenant Name"
                variant="outlined"
                margin="normal"
                fullWidth
                value={tenantName}
                onChange={handleTenantNameChange}
                required
                aria-label="username"
                style={{ backgroundColor: "white", borderRadius: 10 }}
                InputLabelProps={{
                  style: {
                    color: "grey",
                    shrink: true,
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "0.95rem",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.7rem",
                    },
                  },
                }}
              />
              <TextField
                label="Tenant domain" //name.com
                placeholder="TenantName.com"
                variant="outlined"
                margin="normal"
                fullWidth
                value={tenantDomain}
                onChange={handleTenantDomainChange}
                required
                aria-label="username"
                style={{ backgroundColor: "white", borderRadius: 10 }}
                InputLabelProps={{
                  style: {
                    color: "grey",
                    shrink: true,
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "0.95rem",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.7rem",
                    },
                  },
                }}
              />
              <TextField
                label="Config data" // json object
                variant="outlined"
                margin="normal"
                fullWidth
                value={configData}
                onChange={handleConfigDataChange}
                required
                aria-label="username"
                style={{ backgroundColor: "white", borderRadius: 10 }}
                InputLabelProps={{
                  style: {
                    color: "grey",
                    shrink: true,
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "0.95rem",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.7rem",
                    },
                  },
                }}
              />
              <TextField
                label="Vector Database URL" // http://
                placeholder="http://13.31.783.59:2000/"
                variant="outlined"
                margin="normal"
                fullWidth
                value={vectorDbUrl}
                onChange={handleVectorDbUrlChange}
                required
                aria-label="username"
                style={{ backgroundColor: "white", borderRadius: 10, marginBottom: theme.spacing(3) }}
                InputLabelProps={{
                  style: {
                    color: "grey",
                    shrink: true,
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "0.95rem",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.7rem",
                    },
                  },
                }}
              />
              <Typography className={classes.subHeading}>
                Tenant Admin Information
              </Typography>
              <TextField
                label="Tenant Admin Name"
                variant="outlined"
                margin="normal"
                fullWidth
                value={name}
                onChange={handleNameChange}
                required
                aria-label="name"
                style={{ backgroundColor: "white", borderRadius: 10 }}
                InputLabelProps={{
                  style: {
                    color: "grey",
                    shrink: true,
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "0.95rem",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.7rem",
                    },
                  },
                }}
              />
              <TextField
                label="Email"
                variant="outlined"
                margin="normal"
                fullWidth
                value={user_email}
                onChange={handleUserEmailChange}
                required
                aria-label="email"
                style={{ backgroundColor: "white", borderRadius: 10 }}
                InputLabelProps={{
                  style: {
                    color: "grey",
                    shrink: true,
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "0.95rem",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.7rem",
                    },
                  },
                }}
              />
              <TextField
                label="Department"
                variant="outlined"
                margin="normal"
                fullWidth
                value={department}
                onChange={handleDepartmentChnage}
                required
                aria-label="department"
                style={{ backgroundColor: "white", borderRadius: 10 }}
                InputLabelProps={{
                  style: {
                    color: "grey",
                    shrink: true,
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "0.95rem",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.7rem",
                    },
                  },
                }}
              />
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <Button
                  to="/"
                  variant="outlined"
                  color="primary"
                  type="submit"
                  size="large"
                  style={{ mt: 3, textDecoration: "none", padding: "0.7em" }}
                  className={classes.cancelButton}
                  onClick={() => (window.location.href = "/tenant-management")}
                >
                  Cancel
                </Button>
                <Button
                  // disabled={loading}
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  className={classes.button}
                >
                  {loading ? "Creating . . ." : "Create"}
                </Button>
              </Box>
            </form>
          </Box>
        </Paper>
        <SnackbarAlert
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={handleCloseSnackbar}
        />
      </Box>
      <Footer />
    </Box>
  );
}

export default AddTenant;
