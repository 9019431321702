import React from "react";
import { Box, Typography, Link } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { Margin } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: "#FFC629",
    textAlign: "center",
    position: "relative",
    bottom: 0,
  },
  footer: {
    fontSize: "0.8rem !important",
    fontFamily: "Raleway, sans-serif !important",
    color: "#747171",
  },
  link: {
    fontSize: "0.8rem !important",
    color: "#747171 !important",
    cursor: "pointer",
    textDecoration: "none !important",
    fontWeight: "bold !important",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleTermsClick = () => {
    navigate("/terms-and-conditions");
  };

  return (
    <Box className={classes.footerContainer}>
      <Typography className={classes.footer}>
        Copyright &copy; ACE 2024 | Powered by AIZATRON |
      </Typography>
      <Link onClick={handleTermsClick} className={classes.link} style={{marginLeft: "0.2rem"}}>
        Terms and Conditions
      </Link>
    </Box>
  );
};

export default Footer;
