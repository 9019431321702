import React, { createContext, useState, useEffect } from 'react';

export const TenantContext = createContext();

export const TenantProvider = ({ children }) => {
  const [tenantInfo, setTenantInfo] = useState(() => {
    const savedTenantInfo = localStorage.getItem('tenant_info');
    return savedTenantInfo ? JSON.parse(savedTenantInfo) : null;
  });

  useEffect(() => {
    if (tenantInfo) {
      localStorage.setItem('tenant_info', JSON.stringify(tenantInfo));
    }
  }, [tenantInfo]);

  return (
    <TenantContext.Provider value={{ tenantInfo, setTenantInfo }}>
      {children}
    </TenantContext.Provider>
  );
};
