import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../components/Navigation";
import Footer from "../components/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    padding: theme.spacing(0.2),
  },
  title: {
    color: "#ffffff",
    fontSize: "2rem",
    fontWeight: "bold",
    marginTop: theme.spacing(14),
    marginBottom: theme.spacing(5),
    // alignSelf: "center",
    fontFamily: "Raleway, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem", // font size for smaller screens
    },
  },
  TCs: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    flexWrap: "wrap",
    margin: "auto",
    width: "100%",
    textAlign: "start",
    justifyContent: "center",
    marginTop: theme.spacing(6),
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    marginBottom: theme.spacing(7),
  },
  TCsText: {
    color: "#FFFFFF",
    // color: "#747171",
    gap: "8px",
  },
}));

const TermsAndConditions = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Navbar />

      <Box className={classes.TCs}>
        <Typography className={classes.title}>Terms and Conditions</Typography>
        <Box className={classes.column}>
          <Typography className={classes.TCsText}>
            <Typography
              style={{ fontFamily: "Raleway", marginBottom: "0.5rem" }}
            >
              <b>1. Test Environment</b> <br />
              The PoC Platform is running on a test environment and is subject
              to change without prior notice. The platform is designed for
              testing and demonstration purposes only and may not represent the
              final product or its features.
            </Typography>
            <Typography
              style={{ fontFamily: "Raleway", marginBottom: "0.5rem" }}
            >
              <b>2. Security and Data Privacy</b> <br />
              While every effort is made to keep your data private, the PoC
              Platform does not implement the same security measures as the
              production environment. Users should be aware that the platform
              lacks certain controls found in the production version, and
              therefore sensitive or confidential data should not be used in
              this environment.
            </Typography>
            <Typography
              style={{ fontFamily: "Raleway", marginBottom: "0.5rem" }}
            >
              <b>3. Data Compliance</b> <br />
              The PoC Platform adheres to the data protection regulations
              outlined by the General Data Protection Regulation (GDPR) of the
              European Union and the Protection of Personal Information Act
              (POPIA) of South Africa. However, the platform's security
              framework in its current state does not comply with the
              production-level security standards.
            </Typography>
            <Typography
              style={{ fontFamily: "Raleway", marginBottom: "0.5rem" }}
            >
              <b>4. System Modifications and Performance</b> <br />
              The PoC platform runs on test servers, which are actively used for
              various tests. This may impact system performance or lead to
              temporary downtime. The code and functionality of the platform may
              be altered or updated without prior notice as part of the testing
              process.
            </Typography>
            <Typography
              style={{ fontFamily: "Raleway", marginBottom: "0.5rem" }}
            >
              <b>5. Service Availability</b> <br />
              The system may be taken offline at any time without warning. There
              is no guarantee of continuous availability or uptime during the
              PoC phase, and interruptions may occur frequently due to ongoing
              tests.
            </Typography>
            <Typography
              style={{ fontFamily: "Raleway", marginBottom: "0.5rem" }}
            >
              <b>6. No Confidential Data Usage</b> <br />
              Due to the absence of production-level security controls, users
              are strongly advised not to input or upload any confidential,
              sensitive, or personal data onto the PoC platform. Users are
              responsible for ensuring that the information entered into the
              system complies with these guidelines.
            </Typography>
            <Typography
              style={{ fontFamily: "Raleway", marginBottom: "0.5rem" }}
            >
              <b>7. Use at Own Risk</b> <br />
              The use of the PoC Platform is entirely at the user's own risk.
              Aizatron accepts no liability for any loss, damages, or
              inconvenience caused due to the use of the PoC platform.
            </Typography>
            <Typography
              style={{ fontFamily: "Raleway", marginBottom: "0.5rem" }}
            >
              <b>8. Fair Use Policy</b> <br />
              Users are expected to use the platform responsibly and in line
              with its intended purpose as a Proof of Concept. Excessive or
              abnormal usage that disrupts the system, degrades performance, or
              interferes with other users' access will be considered a violation
              of this policy.
            </Typography>
            <Typography style={{ fontFamily: "Raleway" }}>
              <b>9. Platform Abuse</b> <br />
              Any abuse or misuse of the platform, including but not limited to
              attempts to exploit vulnerabilities, access unauthorised data, or
              disrupt service for other users, will result in immediate
              suspension or permanent banning from the platform without notice.
              Aizatron reserves the right to take appropriate action in such
              cases.
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default TermsAndConditions;
