import React, { useState, useContext } from "react";
import logo from "../assets/Aizatron-logo_white_trans.png";
import { Box, Typography, Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import API_ENDPOINTS from "../utilities/APIRoutes";
import { UserContext } from "../contexts/UserContext";
import SnackbarAlert from "../components/SnackbarAlert";
import Footer from "../components/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    padding: theme.spacing(0.2),
  },
  logo: {
    margin: theme.spacing(2),
    width: "8em",
    [theme.breakpoints.down("sm")]: {
      width: "6em", // logo size for smaller screens
    },
  },
  centerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    flexGrow: 1,
    fontFamily: "Raleway, sans-serif",
    marginTop: theme.spacing(-8),
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    width: theme.spacing(40),
    marginBottom: theme.spacing(2),
    alignItems: "flex-center",
  },
  title: {
    color: "#ffffff",
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
    fontFamily: "Raleway, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem", // font size for smaller screens
    },
  },
  description: {
    color: "#ffffff",
    fontSize: "1.4rem",
    marginBottom: theme.spacing(4),
    fontFamily: "Raleway, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem", // font size for smaller screens
    },
  },
  footerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    backgroundColor: "#FFC629",
    padding: theme.spacing(2),
  },
  footer: {
    color: "#747171",
    fontSize: "0.8rem",
    fontFamily: "Raleway, sans-serif",
  },
  button: {
    backgroundColor: "#FFC629",
    color: "#000000",
    borderRadius: "10px",
    padding: theme.spacing(1, 3),
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "none",
    marginTop: theme.spacing(2),
    fontFamily: "Raleway, sans-serif",
    "&:hover": {
      backgroundColor: "#FFC629",
      color: "#000000",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem", // font size for smaller screens
    },
  },
}));

function LoginPage() {
  const [username, setUsername] = useState("");
  const { userInfo, setUserInfo } = useContext(UserContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const updateUserInfo = (username) => {
    setUserInfo({ email: username });
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    //  GetSignInURL API CALL
    try {
      const response = await fetch(API_ENDPOINTS.GetSignInURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: username,
        }),
      });

      const result = await response.json();

      if (result.error === true && result.message === "tenants not found") {
        setSnackbarMessage("Please check your email!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }

      let signinURL = result.signinURL;
      signinURL = signinURL.split("&");

      // signinURL[3] = encodeURIComponent("redirect_uri=http://localhost:3000");
      // signinURL[3] = encodeURIComponent(
      //   "redirect_uri=https://d1pbn2l9efw2hp.cloudfront.net/"
      // );
      signinURL[3] = encodeURIComponent("redirect_uri=https://ace.aizatroncloud.com/");
      // signinURL[3] = encodeURIComponent("redirect_uri=https://d1r8s8ixh3ppcn.cloudfront.net/")
      signinURL = signinURL.join("&");

      updateUserInfo(username);

      window.location.href = result.signinURL;
    } catch (error) {
      console.log(error.message);
    }
  };

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box style={{ alignSelf: "flex-start" }}>
        <img src={logo} alt="Logo" className={classes.logo} />
      </Box>
      <Box className={classes.centerContent}>
        <Typography variant="h1" className={classes.title}>
          Login
        </Typography>
        <form onSubmit={handleLogin} className={classes.inputContainer}>
          <TextField
            label="Enter email"
            variant="outlined"
            margin="normal"
            fullWidth
            value={username}
            onChange={handleUsernameChange}
            required
            aria-label="username"
            style={{ backgroundColor: "white", borderRadius: 10 }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            sx={{ mt: 3 }}
            className={classes.button}
          >
            Login
          </Button>
        </form>
      </Box>
      <SnackbarAlert
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}
      />
      <Footer />
    </Box>
  );
}

export default LoginPage;
