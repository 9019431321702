import React, { useState, useEffect, useCallback, useRef } from "react";
import Navbar from "../components/Navigation";
import {
  Box,
  Typography,
  FormControl,
  MenuItem,
  Button,
  TextField,
  InputBase,
  Paper,
  useTheme,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { Pagination } from "@mui/material";
import Modal from "@mui/material/Modal";
import SnackbarAlert from "../components/SnackbarAlert";
import Footer from "../components/Footer";
import API_ENDPOINTS from "../utilities/APIRoutes";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FolderOffRoundedIcon from "@mui/icons-material/FolderOffRounded";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import TokenOutlinedIcon from "@mui/icons-material/TokenOutlined";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import { useDropzone } from "react-dropzone";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    padding: theme.spacing(0.2),
    overflow: "hidden",
  },
  centerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    flexGrow: 1,
    fontFamily: "Raleway, sans-serif",
    marginTop: theme.spacing(13),
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    width: theme.spacing(80),
    marginBottom: theme.spacing(2),
    alignItems: "flex-center",
  },
  input: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent !important",
      },
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      // borderColor: "#FFC629 !important",
      border: "none",
    },
    "& .MuiSelect-icon": {
      color: "grey !important",
    },
  },
  title: {
    color: "#ffffff",
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    fontFamily: "Raleway, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem", // font size for smaller screens
    },
  },
  description: {
    color: "#ffffff",
    fontSize: "1.2rem",
    marginBottom: theme.spacing(4),
    fontFamily: "Raleway, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem", // font size for smaller screens
    },
  },
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    backgroundColor: "#000000",
    borderRadius: 14,
    padding: theme.spacing(4),
    marginBottom: theme.spacing(8),
    width: 1100,
    // flexGrow: 1,
    overflow: "hidden",
  },
  boxHeading: {
    color: "#ffffff",
    fontSize: "0.9rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    fontFamily: "Raleway, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem", // font size for smaller screens
    },
  },
  chat: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    height: theme.spacing(70),
    // height: "100%",
    width: "-webkit-fill-available",
    borderRadius: 9,
    padding: theme.spacing(1),
  },
  text: {
    color: "#ffffff",
    fontFamily: "Raleway, sans-serif",
    fontSize: "0.9rem",
    fontWeight: "bold",
  },
  listItem: {
    justifyContent: "space-between",
    backgroundColor: "#303030",
    marginBottom: "0.9em",
    borderRadius: 8,
  },
  listItemText: {
    fontFamily: "Raleway, sans-serif",
    color: "white",
    fontSize: "2em",
  },
  button: {
    backgroundColor: "#FFC629",
    color: "#000000",
    borderRadius: "10px",
    padding: theme.spacing(1, 3),
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "none",
    marginTop: theme.spacing(2),
    fontFamily: "Raleway, sans-serif",
    "&:hover": {
      backgroundColor: "#FFC629",
      color: "#000000",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem", // font size for smaller screens
    },
  },
  footerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    backgroundColor: "#FFC629",
    padding: theme.spacing(2),
  },
  footer: {
    color: "#747171",
    fontSize: "0.8rem",
    fontFamily: "Raleway, sans-serif",
  },
  chatContainer: {
    display: "flex",
    flexDirection: "column",
    height: "450px",
    width: "100%", // Full width within parent
    // backgroundColor: "#1a1a1a", // Background color for chat section
    borderRadius: 9,
    padding: theme.spacing(1),
    overflow: "hidden",
  },
  chatHistory: {
    flexGrow: 1,
    overflowY: "auto",
    padding: theme.spacing(1),
    maxHeight: "400px",
  },
  chatInputContainer: {
    padding: theme.spacing(1),
    borderTop: "1px solid #444",
    // backgroundColor: "#1a1a1a", // Match the background of the chat
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#202020",
    borderRadius: "9px",
    padding: theme.spacing(5),
  },
}));

function MainChat() {
  const theme = useTheme();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingRes, setLoadingRes] = useState(false);
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedURL, setSelectedURL] = useState([]);
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false); // Modal state
  const [fileToDelete, setFileToDelete] = useState(null);
  const [userQuery, setUserQuery] = useState("");
  const [chatHistory, setChatHistory] = useState("");
  const [chatId, setChatId] = useState(null);
  const lastMessageRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [totalTokensUsed, setTotalTokensUsed] = useState(0);
  const [tokenLimit, setTokenLimit] = useState(400000);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("Must start with https://");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const UserToken = localStorage.getItem("userToken");
  const AuthToken = localStorage.getItem("authToken");
  const userRole = localStorage.getItem("role");

  const canUploadOrDelete =
    userRole !== "user" ||
    (userRole === "user" && selectedCollection?.type === "private");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const filesPerPage = 5; // Set number of files per page

  // Calculate the files to be displayed on the current page
  const paginatedFiles = files.slice(
    (currentPage - 1) * filesPerPage,
    currentPage * filesPerPage
  );

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const initialTokenUsage = parseInt(localStorage.getItem("tokenUsage")) || 0;
    const tokenLimit = parseInt(localStorage.getItem("tokenLimit"));
    setTotalTokensUsed(initialTokenUsage);
    setTokenLimit(tokenLimit);

    const fetchCollections = async () => {
      try {
        const response = await fetch(API_ENDPOINTS.ListCollections, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "User-Token": UserToken,
            Authorization: AuthToken,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch collections");
        }

        const data = await response.json();
        const publicCollections = data.message.publicResults;
        const privateCollections = data.message.privateResults;

        const collections = [...publicCollections, ...privateCollections];
        setCollections(collections);
      } catch (error) {
        console.error("Error fetching collections:", error);
      }
    };

    fetchCollections();
  }, []);

  const fetchFiles = async (collectionId) => {
    try {
      const response = await fetch(API_ENDPOINTS.RetrieveCollection, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "User-Token": UserToken,
          Authorization: AuthToken,
        },
        body: JSON.stringify({
          id: collectionId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch files");
      }

      const data = await response.json();
      const fetchedFiles = data.message?.Files || [];
      setFiles(fetchedFiles);
    } catch (error) {
      console.error("Error fetching files:", error);
      setFiles([]);
    }
  };

  const handleCollectionChange = async (event) => {
    const selectedCollection = collections.find(
      (collection) => collection.id === event.target.value
    );
    setSelectedCollection(selectedCollection);
    setChatHistory([]);

    try {
      const newChatId = await createChat(
        selectedCollection.id,
        selectedCollection.name
      );
      setChatId(newChatId); // Store the new chat ID
      console.log("New Chat ID:", newChatId);
      fetchFiles(selectedCollection.id);
    } catch (error) {
      console.error("Error changing collection:", error);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile((prevFiles) => [...prevFiles, ...acceptedFiles]); // Allow multiple files
  }, []);

  const handleUploadClick = () => {
    if (
      (selectedFile.length > 0 || selectedURL.length > 0) &&
      selectedCollection
    ) {
      setLoading(true);
      uploadFile(selectedFile, selectedURL, selectedCollection);
    } else {
      setSnackbarMessage("File or Collection not selected");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error("File or Collection not selected");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/pdf,.docx,.xlsx, .txt",
    onDrop,
  });

  const handleUrlChange = (e) => {
    const value = e.target.value;
    setSelectedURL(value);

    if (!value.startsWith("https://")) {
      setError(true);
      setHelperText('URL must start with "https://".');
    } else {
      setError(false);
      setHelperText("");
    }
  };

  // Remove file from selectedFiles list
  const removeFile = (fileIndex) => {
    setSelectedFile((prevFiles) =>
      prevFiles.filter((_, index) => index !== fileIndex)
    );
  };

  // Remove URL from selectedURLs list
  const removeURL = (urlIndex) => {
    setSelectedURL((prevURLs) =>
      prevURLs.filter((_, index) => index !== urlIndex)
    );
  };

  const uploadFile = async (files, urls) => {
    if (!selectedCollection || (files.length === 0 && urls.length === 0)) {
      console.error("File/URL or Collection not selected");
      setSnackbarMessage("File/URL or Collection not selected");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("files", file);
    });

    if (urls && typeof urls === "string") {
      const url_list = urls.split(",");
      formData.append("urls", url_list);
    }

    formData.append("collection_id", selectedCollection.id);
    formData.append("collection_name", selectedCollection.name);

    try {
      // console.log("file - ", files);
      // console.log("url - ", urls);
      // console.log("collection id - ", selectedCollection.id);
      // console.log("collection name - ", selectedCollection.name);
      // console.log("form data - ", formData);
      const response = await fetch(
        API_ENDPOINTS.UploadDocAI,
        {
          method: "POST",
          headers: {
            // "Content-Type": "application/json",
            "user-token": UserToken,
            "authorization-token": AuthToken,
          },
          body: formData,
        },
        true
      );

      if (response.status !== 200) {
        throw new Error("Failed to upload");
      }

      if (response.ok || response.success === true) {
        setSelectedFile([]);
        setSelectedURL([]);

        setSnackbarMessage("Uploaded successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        // Refresh the list of files after a successful upload
        fetchFiles(selectedCollection.id);
      }
    } catch (error) {
      setSnackbarMessage("Error uploading document");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error("Error uploading document:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClickOpen = (file) => {
    setFileToDelete(file);
    setOpen(true); // Open modal
  };

  const handleClose = () => {
    setOpen(false); // Close modal without deleting
  };

  const handleConfirmDelete = async () => {
    if (!fileToDelete) return;
    setLoading(true);
    try {
      const response = await fetch(
        API_ENDPOINTS.DeleteDocAI,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "user-token": UserToken,
            "authorization-token": AuthToken,
          },
          body: JSON.stringify({
            collection_name: selectedCollection.name,
            document_name: fileToDelete.FileName,
            document_id: fileToDelete.FileID,
          }),
        },
        true
      );

      if (!response.ok) {
        throw new Error("Failed to delete file");
      }

      // Remove the deleted file from the list
      setFiles(files.filter((f) => f.FileID !== fileToDelete.FileID));
      setSnackbarMessage("File deleted successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error deleting file:", error);
      setSnackbarMessage("Error deleting file");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
      setOpen(false); // Close modal after deletion
      setFileToDelete(null); // Clear file selection
    }
  };

  const createChat = async (collectionId, collectionName) => {
    const userId = localStorage.getItem("user_id");
    const userName = localStorage.getItem("name");
    const chatName = `${userName} - ${collectionName}`;

    try {
      const response = await fetch(API_ENDPOINTS.CreateChat, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
          "User-Token": UserToken,
        },
        body: JSON.stringify({
          user_id: userId,
          chatName: chatName,
          collectionID: collectionId,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        return data.message.id; // Assuming the chat ID is returned in the response with key 'id'
      } else {
        console.error("Failed to create chat");
        return null;
      }
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  const getChatResponse = async (chatId, collectionName, query) => {
    try {
      const url = new URL(API_ENDPOINTS.GetChatResponse);
      url.searchParams.append("chat_id", chatId);
      url.searchParams.append("collection_name", collectionName);
      url.searchParams.append("query", query);

      const response = await fetch(
        url,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "authorization-token": AuthToken,
            "user-token": UserToken,
          },
        },
        true
      );

      if (response.ok) {
        const data = await response.json();

        // Extract the response and token usage data
        const chatResponse = data.data.response;
        // const tokensUsed = data.data.token_usage.tokens_used;
        // setTotalTokensUsed((prevTokens) => prevTokens + tokensUsed); // Add the tokens used to the total

        if (data && data.data.token_usage.tokens_used) {
          const tokensUsed = data.data.token_usage.tokens_used;
          let currentTokenUsage =
            parseInt(localStorage.getItem("tokenUsage")) || 0;

          currentTokenUsage += tokensUsed;
          localStorage.setItem("tokenUsage", currentTokenUsage);
          setTotalTokensUsed(currentTokenUsage);
        }

        return chatResponse;
      } else {
        console.error("Failed to get chat response");
        return null;
      }
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  const handleSubmitQuery = async () => {
    if (!selectedCollection || Object.keys(selectedCollection).length === 0) {
      // Trigger the error snackbar if no collection is selected
      setSnackbarMessage("Please select a collection before sending a query!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    if (userQuery.trim() === "") return;

    const collectionId = selectedCollection.id;
    const collectionName = selectedCollection.name;

    setLoadingRes(true);
    // Add the user's query to the chat history
    setChatHistory((prevHistory) => [
      ...prevHistory,
      { sender: "user", message: userQuery },
    ]);

    try {
      let currentChatId = chatId; // Use existing chatId if available
      if (!chatId) {
        currentChatId = await createChat(collectionId, collectionName);
        setChatId(currentChatId); // Store the chatId after the first chat creation
        console.log("Chat ID:", currentChatId);
      }

      if (currentChatId) {
        const response = await getChatResponse(
          currentChatId,
          collectionName,
          userQuery
        );

        if (response) {
          // Add the response to the chat history
          setChatHistory((prevHistory) => [
            ...prevHistory,
            { sender: "bot", message: response },
          ]);
        } else {
          console.error("No response received from getChatResponse");
        }
      } else {
        console.error("Invalid chatId returned from createChat");
      }
    } catch (error) {
      console.error("Error in handleSubmitQuery:", error);
    } finally {
      setLoading(false);
    }

    // Clear the query input
    setUserQuery("");

    // Scroll to the latest message if container height exceeds the set height
    if (
      chatContainerRef.current &&
      chatContainerRef.current.scrollHeight >
        chatContainerRef.current.clientHeight
    ) {
      setTimeout(() => {
        if (lastMessageRef.current) {
          lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    }
  };

  return (
    <Box className={classes.root}>
      <Navbar />
      <Box className={classes.centerContent}>
        <Typography className={classes.title}>
          Welcome to the Aizatron Cognitive Engine chatbot!
        </Typography>
        <Typography className={classes.description}>
          Streamline the management of your valuable information through curated
          collections.
        </Typography>
        <Box className={classes.boxContainer}>
          <Typography className={classes.boxHeading}>
            Choose a Collection
          </Typography>
          <FormControl variant="filled" style={{ width: "100%" }}>
            <TextField
              select
              label="Select a collection"
              value={selectedCollection ? selectedCollection.id : ""}
              onChange={handleCollectionChange}
              variant="outlined"
              margin="normal"
              className={classes.input}
              style={{
                backgroundColor: "white",
                borderRadius: 10,
              }}
              InputLabelProps={{
                style: {
                  visibility: selectedCollection ? "hidden" : "visible",
                  color: "grey",
                  shrink: true,
                  fontFamily: "Raleway, sans-serif",
                  fontSize: "0.95rem",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.7rem",
                  },
                },
              }}
              InputProps={{
                style: {
                  "& .MuiInputBaseInput:focus": {
                    outline: "none",
                  },
                  borderRadius: "12px",
                  color: "black",
                  textAlign: "left",
                  fontFamily: "Raleway, sans-serif",
                  fontSize: "0.95rem",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.7rem",
                  },
                },
              }}
            >
              {collections.map((collection) => (
                <MenuItem
                  key={collection.id}
                  value={collection.id}
                  style={{
                    color: "black",
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "0.95rem",
                    [theme.breakpoints.down("sm")]: { fontSize: "0.7rem" },
                  }}
                >
                  {collection.name} | {collection.type}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Box>
        <Box className={classes.boxContainer}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "-webkit-fill-available",
              alignItems: "center",
            }}
          >
            <Typography className={classes.boxHeading}>Chat Box</Typography>
            <Box style={{ display: "flex", alignContent: "center" }}>
              <TokenOutlinedIcon sx={{ color: "#FFC629" }} />
              <Typography
                variant="inherit"
                style={{ color: "white", marginLeft: theme.spacing(0.5) }}
              >
                {totalTokensUsed} / {tokenLimit}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.chatContainer} ref={chatContainerRef}>
            {/* Display the chat history */}
            <Box className={classes.chatHistory}>
              <List>
                {chatHistory.length === 0 ? (
                  <Typography
                    variant="h6"
                    style={{
                      color: "grey",
                      textAlign: "center",
                      marginTop: theme.spacing(2),
                      fontFamily: "Raleway, sans-serif",
                    }}
                  >
                    Start a chat by selecting a collection & sending your
                    message
                  </Typography>
                ) : (
                  <List>
                    {chatHistory.map((chat, index) => (
                      <ListItem
                        key={index}
                        ref={
                          index === chatHistory.length - 1
                            ? lastMessageRef
                            : null
                        }
                      >
                        <ListItemText
                          primary={chat.message}
                          secondary={chat.sender === "user" ? "You" : "ACE"}
                          primaryTypographyProps={{
                            style: {
                              fontFamily: "Raleway, sans-serif",
                              fontWeight: "bold",
                              fontSize: "0.9em",
                            },
                          }}
                          secondaryTypographyProps={{
                            style: {
                              fontFamily: "Raleway, sans-serif",
                              color: "#FFC629",
                            },
                          }}
                          style={{
                            backgroundColor:
                              chat.sender === "user"
                                ? "#1a1a1a"
                                : "transparent",
                            color: chat.sender === "user" ? "white" : "white",
                            borderRadius: 8,
                            padding: "8px 16px",
                            margin: "4px 0",
                            textAlign:
                              chat.sender === "user" ? "right" : "left",
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </List>
            </Box>
            <Box className={classes.chatInputContainer}>
              <Paper
                style={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: 8,
                  // height: "max-content"
                }}
              >
                <InputBase
                  id="outlined-multiline-flexible"
                  placeholder="Message ACE . . . "
                  multiline
                  maxRows={4}
                  value={userQuery}
                  onChange={(e) => setUserQuery(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleSubmitQuery();
                      setUserQuery("");
                    }
                  }}
                  style={{
                    color: "black",
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "0.9rem",
                    // height: "2.5rem",
                    padding: 8,
                    width: "-webkit-fill-available",
                  }}
                />
                <IconButton
                  disabled={loadingRes}
                  type="button"
                  style={{
                    backgroundColor: "#FFC629",
                    borderRadius: 8,
                    margin: "0.2rem",
                  }}
                  onClick={handleSubmitQuery}
                >
                  {loadingRes ? <StopRoundedIcon /> : <SendRoundedIcon />}
                </IconButton>
              </Paper>
            </Box>
          </Box>
        </Box>
        {canUploadOrDelete && (
          <Box className={classes.boxContainer}>
            <Typography className={classes.boxHeading}>
              Upload Documents
            </Typography>
            <Paper
              style={{
                padding: 2,
                backgroundColor: "black",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "-webkit-fill-available",
              }}
            >
              <TextField
                label="Enter URL (comma-separated)"
                variant="outlined"
                helperText={helperText}
                value={selectedURL}
                onChange={handleUrlChange}
                margin="normal"
                fullWidth
                InputLabelProps={{
                  style: {
                    color: "grey",
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "0.95rem",
                    [theme.breakpoints.down("sm")]: { fontSize: "0.7rem" },
                  },
                }}
                InputProps={{
                  style: {
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "9px",
                    fontFamily: "Raleway, sans-serif",
                    fontSize: "1rem",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.7rem",
                    },
                  },
                }}
                FormHelperTextProps={{
                  style: { color: error ? "red" : "grey" },
                }}
                style={{
                  marginBottom: theme.spacing(2),
                }}
              />
              <Box
                style={{
                  border: "1px solid grey",
                  borderStyle: "dashed",
                  padding: 20,
                  borderRadius: 9,
                  width: "40%",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Button className={classes.button}>Browse Files</Button>
                <Typography
                  style={{ marginTop: theme.spacing(2) }}
                  className={classes.text}
                >
                  or drop files here
                </Typography>
                {/* <CloudUploadRoundedIcon sx={{ color: "white", width: 40, height: 40 }}/> */}
                <Typography
                  style={{
                    marginTop: theme.spacing(1),
                    fontSize: "0.7rem",
                    color: "lightGrey",
                    fontWeight: "lighter",
                    fontFamily: "Raleway",
                  }}
                >
                  Maximum file size 50mb
                </Typography>
              </Box>

              {/* Display selected files */}
              {selectedFile.length > 0 && (
                <Box>
                  {selectedFile.map((file, index) => (
                    <Box
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className={classes.text}>
                        {file.name} ({file.size} bytes)
                      </Typography>
                      <IconButton onClick={() => removeFile(index)}>
                        <CancelRoundedIcon
                          sx={{ color: "grey", width: 20, height: 20 }}
                        />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              )}

              {/* Display selected URLs */}
              {/* {selectedURL.length > 0 && (
                <Box>
                  {selectedURL.map((url, index) => (
                    <Box
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className={classes.text}>{url}</Typography>
                      <IconButton onClick={() => removeURL(index)}>
                        <CancelRoundedIcon
                          sx={{ color: "grey", width: 20, height: 20 }}
                        />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              )} */}

              {/* Upload button */}
              <Button
                disabled={
                  (selectedFile.length === 0 && selectedURL.length === 0) ||
                  loading
                }
                onClick={handleUploadClick}
                className={classes.button}
              >
                {loading ? "Uploading . . ." : "Upload"}
              </Button>
            </Paper>
          </Box>
        )}
        {canUploadOrDelete && (
          <Box className={classes.boxContainer}>
            <Typography className={classes.boxHeading}>Documents</Typography>
            {Array.isArray(files) && files.length > 0 ? (
              <List
                style={{
                  padding: 1,
                  backgroundColor: "black",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "start",
                  width: "-webkit-fill-available",
                }}
              >
                {/* <Typography className={classes.text}>
                  {selectedCollection.name}:
                </Typography> */}
                {paginatedFiles.map((file) => (
                  <ListItem key={file.FileID} className={classes.listItem}>
                    <ListItemText
                      primary={file.FileName}
                      className={classes.text}
                    />
                    <IconButton
                      aria-label="delete"
                      style={{
                        backgroundColor: "#FFC629",
                        borderRadius: 4,
                        width: 25,
                        height: 25,
                      }}
                      onClick={() => handleClickOpen(file)}
                    >
                      <DeleteOutlineOutlinedIcon
                        sx={{ color: "black", width: 20, height: 20 }}
                      />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Box
                style={{
                  padding: 2,
                  backgroundColor: "black",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "-webkit-fill-available",
                  color: "white",
                  marginTop: theme.spacing(4),
                  marginBottom: theme.spacing(4),
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    marginBottom: 16,
                    fontFamily: "Raleway, sans-serif",
                  }}
                >
                  This collection is empty
                </Typography>
                <FolderOffRoundedIcon
                  style={{ fontSize: 50, color: "#FFC629" }}
                />
              </Box>
            )}
            {/* Pagination Controls */}
            {files.length > filesPerPage && (
              <Pagination
                sx={{
                  display: "inline-flex",
                  alignSelf: "center",
                  backgroundColor: "#FFC629",
                  borderRadius: 1,
                  width: "fit-content",
                }}
                size="small"
                shape="rounded"
                variant="outined"
                count={Math.ceil(files.length / filesPerPage)}
                page={currentPage}
                onChange={handlePageChange}
              />
            )}
            {/* Confirmation Modal */}
            <Modal open={open} onClose={handleClose}>
              <Box className={classes.modal}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    textAlign: "center",
                    fontFamily: "Raleway",
                    fontWeight: "bolder",
                    color: "white",
                    fontSize: "1.5rem",
                  }}
                >
                  Are you sure you want to delete {fileToDelete?.FileName}?
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginTop: theme.spacing(3),
                  }}
                >
                  <Button
                    disabled={loading}
                    onClick={handleConfirmDelete}
                    style={{
                      backgroundColor: "#FFC629",
                      color: "#000000",
                      borderRadius: "10px",
                      padding: theme.spacing(1, 3),
                      fontSize: "1rem",
                      fontWeight: "bold",
                      textTransform: "none",
                      fontFamily: "Raleway, sans-serif",
                      "&:hover": {
                        backgroundColor: "#FFC629",
                        color: "#000000",
                      },
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "0.9rem", // font size for smaller screens
                      },
                    }}
                  >
                    {loading ? "Deleting . . ." : "Delete"}
                  </Button>
                  <Button
                    onClick={handleClose}
                    style={{
                      backgroundColor: "#F5F5F5",
                      color: "#000000",
                      borderRadius: "10px",
                      padding: theme.spacing(1, 3),
                      fontSize: "1rem",
                      fontWeight: "bold",
                      textTransform: "none",
                      fontFamily: "Raleway, sans-serif",
                      "&:hover": {
                        backgroundColor: "#FFC629",
                        color: "#000000",
                      },
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "0.9rem",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Modal>
          </Box>
        )}
        <SnackbarAlert
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={handleCloseSnackbar}
        />
      </Box>
      <Footer />
    </Box>
  );
}
export default MainChat;
