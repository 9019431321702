import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import alterLogo from "../assets/Aizatron logo_alt_white_trans.png";
import { Box } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  navLink: {
    marginLeft: "0.8em",
    color: "white",
    textDecoration: "none",
    fontSize: "0.9em",
    fontWeight: "lighter",
    "&:hover": {
      // textDecoration: "underline",
      fontWeight: "bold",
    },
  },
  activeNavLink: {
    fontWeight: "bolder",
    textDecoration: "underline"
  },
}));

function Navbar() {
  const classes = useStyles();
  const userRole = localStorage.getItem("role");

  return (
    <AppBar position="fixed" style={{ backgroundColor: "#1a1a1a" }}>
      <Toolbar style={{ justifyContent: "space-between" }}>
        <Box
          variant="h6"
          component="div"
          sx={{ display: "flex", alignSelf: "flex-end" }}
        >
          <img
            src={alterLogo}
            alt="App Logo"
            style={{ height: 80, width: 150, marginRight: 1 }}
          />
        </Box>
        <Box>
          <NavLink
            to="/main-chat"
            className={({ isActive }) =>
              isActive
                ? `${classes.navLink} ${classes.activeNavLink}`
                : classes.navLink
            }
          >
            Chat
          </NavLink>
          <NavLink
            to="/collections"
            className={({ isActive }) =>
              isActive
                ? `${classes.navLink} ${classes.activeNavLink}`
                : classes.navLink
            }
          >
            Collections
          </NavLink>
          {userRole === "tenant admin" && (
            <NavLink
              to="/user-management"
              className={({ isActive }) =>
                isActive
                  ? `${classes.navLink} ${classes.activeNavLink}`
                  : classes.navLink
              }
            >
              Management
            </NavLink>
          )}
          {userRole === "sys admin" && (
            <NavLink
              to="/tenant-management"
              className={({ isActive }) =>
                isActive
                  ? `${classes.navLink} ${classes.activeNavLink}`
                  : classes.navLink
              }
            >
              Management
            </NavLink>
          )}
          <NavLink
            to="/profile"
            className={({ isActive }) =>
              isActive
                ? `${classes.navLink} ${classes.activeNavLink}`
                : classes.navLink
            }
          >
            Profile
          </NavLink>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
