import React, { useState, useEffect } from "react";
import Navbar from "../components/Navigation";
import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  useTheme,
  InputBase,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
import { Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import Modal from "@mui/material/Modal";
import SnackbarAlert from "../components/SnackbarAlert";
import Footer from "../components/Footer";
import { makeStyles } from "@material-ui/core/styles";
import API_ENDPOINTS from "../utilities/APIRoutes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    padding: theme.spacing(0.2),
  },
  description: {
    alignSelf: "flex-start",
    color: "#ffffff",
    fontSize: "1.2rem",
    fontWeight: "bold",
    fontFamily: "Raleway, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem", // font size for smaller screens
    },
  },
  centerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    fontFamily: "Raleway, sans-serif",
    padding: theme.spacing(13),
  },
  userList: {
    // padding: "1px 38px",
    marginTop: theme.spacing(2),
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#303030",
    color: "#ffffff",
    marginBottom: "1em",
    borderRadius: 8,
  },
  listShare: {
    // display: "flex",
    // justifyContent: "space-between",
    backgroundColor: "#303030",
    color: "#ffffff",
    marginBottom: "0.4rem",
    borderRadius: 8,
  },
  listItemText: {
    // fontFamily: "Raleway",
  },
  listItemTextShare: {
    // fontFamily: "Raleway",
    fontSize: "0.5rem",
  },
  searchUserlistBox: {
    marginTop: theme.spacing(2),
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    width: 300,
    height: 35,
    justifyContent: "space-between",
    backgroundColor: "#f5f5f5",
    borderRadius: 8,
    padding: "2px 12px",
  },
  iconBox: {
    display: "flex",
    width: "6%",
    justifyContent: "space-around",
  },
  button: {
    alignSelf: "center",
    backgroundColor: "#FFC629",
    color: "#000000",
    borderRadius: "10px",
    padding: theme.spacing(1, 5),
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "none",
    marginTop: theme.spacing(5),
    fontFamily: "Raleway, sans-serif",
    "&:hover": {
      backgroundColor: "#FFC629",
      color: "#000000",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
  buttonBox: {
    display: "flex",
    width: "-webkit-fill-available",
    justifyContent: "center",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#202020",
    borderRadius: "14px",
    padding: theme.spacing(5),
  },
  footerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    backgroundColor: "#FFC629",
    padding: theme.spacing(2),
  },
  footer: {
    color: "#747171",
    fontSize: "0.8rem",
    fontFamily: "Raleway, sans-serif",
  },
}));

function CollectionManagement() {
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const [publicCollections, setPublicCollections] = useState([]);
  const [privateCollections, setPrivateCollections] = useState([]);

  const [currentPublicPage, setCurrentPublicPage] = useState(1);
  const [currentPrivatePage, setCurrentPrivatePage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryPrivate, setSearchQueryPrivate] = useState("");

  const [collectionName, setCollectionName] = useState("");
  const [collectionDescription, setCollectionDescription] = useState("");
  const [collectionType, setCollectionType] = useState("");

  const [collectionToDelete, setCollectionToDelete] = useState(null);
  const [collectionToShare, setCollectionToShare] = useState(null);
  const [shareEmail, setShareEmail] = useState("");
  // const [sharedWith, setSharedWith] = useState([]);
  const [sharedUsers, setSharedUsers] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCollectionNameChange = (event) => {
    setCollectionName(event.target.value);
  };

  const handleCollectionDescriptionChange = (event) => {
    setCollectionDescription(event.target.value);
  };

  const handleCollectionTypeChange = (event) => {
    setCollectionType(event.target.value);
  };

  const authToken = localStorage.getItem("authToken");
  const userToken = localStorage.getItem("userToken");
  const userId = localStorage.getItem("user_id");
  const userRole = localStorage.getItem("role");

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await fetch(API_ENDPOINTS.ListCollections, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
            "User-Token": userToken,
          },
        });

        if (response.ok) {
          const data = await response.json();
          const privateCollections = data.message?.privateResults;
          const publicCollections = data.message?.publicResults;
          setPublicCollections(publicCollections);
          setPrivateCollections(privateCollections);
        } else {
          console.error("Failed to fetch collections:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching collections:", error);
      }
    };

    fetchCollections();
  }, []);

  const handleCreateCollection = async () => {
    if (
      !collectionName.trim() ||
      !collectionDescription.trim() ||
      !collectionType
    ) {
      setSnackbarMessage(
        "Please fill out all fields before submitting the form."
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    setLoading(true);
    const requestBody = {
      collection_name: collectionName,
      collection_description: collectionDescription,
      collection_type: collectionType,
    };

    try {
      const response = await fetch(
        API_ENDPOINTS.CreateCollectionAI,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "authorization-token": authToken,
            "user-token": userToken,
          },
          body: JSON.stringify(requestBody),
        },
        true
      );
      const data = await response.json();
      if (response.ok) {

        setCollectionName("");
        setCollectionDescription("");
        setCollectionType("");

        setSnackbarMessage("Collection created successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        // Update the appropriate collection list
        const createdCollection = {
          id: data.collection_id,
          name: collectionName,
          description: collectionDescription,
          ownerID: userId, // Set the owner ID to the logged-in user
        };

        if (collectionType === "public") {
          setPublicCollections((prevCollections) => [
            ...prevCollections,
            createdCollection,
          ]);
        } else if (collectionType === "private") {
          setPrivateCollections((prevCollections) => [
            ...prevCollections,
            createdCollection,
          ]);
        }
      } else {
        console.error("Failed to create collection:", response.statusText);
      }
    } catch (error) {
      console.error("Error creating collection:", error);
      setSnackbarMessage("Something went wrong creating your collection!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCollection = async (
    collectionId,
    collectionName,
    isPublic
  ) => {
    setLoading(true);
    try {
      const response = await fetch(
        API_ENDPOINTS.DeleteCollectionAI,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "authorization-token": authToken,
            "user-token": userToken,
          },
          body: JSON.stringify({
            collection_id: collectionId,
            collection_name: collectionName,
          }),
        },
        true
      );
      if (response.ok) {
        if (isPublic) {
          setPublicCollections((prevCollections) =>
            prevCollections.filter(
              (collection) => collection.id !== collectionId
            )
          );
        } else {
          setPrivateCollections((prevCollections) =>
            prevCollections.filter(
              (collection) => collection.id !== collectionId
            )
          );
        }

        setSnackbarMessage("Collection deleted successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        setCollectionToDelete(null);
      } else {
        console.error("Failed to delete the collection");
        setSnackbarMessage("Something went wrong deleting the collection!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("An error occurred.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleUnshareUser = async (email) => {
    try {
      const response = await fetch(API_ENDPOINTS.UnshareCollection, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
          "User-Token": userToken,
        },
        body: JSON.stringify({
          user_email: email,
          collection_id: collectionToShare.id,
        }),
      });

      const data = await response.json();

      if (data.error === false) {
        setSharedUsers((prevUsers) =>
          prevUsers.filter((user) => user !== email)
        );
        setSnackbarMessage("Successfully un-shared!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error unsharing collection:", error);
      setSnackbarMessage("Something went wrong!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  // Function to fetch shared users
  const fetchSharedUsers = async (collectionId) => {
    try {
      setLoading(true);
      const response = await fetch(API_ENDPOINTS.RetrieveCollection, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
          "User-Token": userToken,
        },
        body: JSON.stringify({ id: collectionId.id }),
      });

      const data = await response.json();

      if (data.error === false && data.message?.SharedWith) {
        setSharedUsers(data.message.SharedWith); // Access the SharedWith array
      } else {
        setSharedUsers([]);
      }
    } catch (error) {
      console.error("Error fetching shared users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleShareCollection = async () => {
    if (!shareEmail.trim()) {
      setSnackbarMessage("Please enter a valid email");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(API_ENDPOINTS.ShareUserCollection, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
          "User-Token": userToken,
        },
        body: JSON.stringify({
          user_email: shareEmail,
          collection_id: collectionToShare.id,
        }),
      });

      const data = await response.json();
      if (data.error === false) {
        fetchSharedUsers();
        setShareEmail("");
        setSnackbarMessage("Collection shared successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        closeShareModal();
      } else if (data.message === "Collection already shared with this user") {
        setSnackbarMessage("Collection already shared with this user");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } else if (data.error === true) {
        console.error("Failed to share the collection:", data.error);
        setSnackbarMessage("Sharing the collection failed!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error sharing collection:", error);
      setSnackbarMessage("An error occurred.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const openShareModal = (collection) => {
    fetchSharedUsers(collection);
    setCollectionToShare(collection);
    setShare(true);
  };

  const closeShareModal = () => {
    setCollectionToShare(null);
    setShare(false);
    setShareEmail("");
    setSharedUsers([]);
  };
  const openDeleteModal = (collection) => {
    setCollectionToDelete({
      id: collection.id,
      name: collection.name,
      isPublic: collection.isPublic,
    });
  };

  const closeDeleteModal = () => {
    setCollectionToDelete(null);
  };

  const pageSize = 4;

  const handlePublicChange = (event, newPage) => {
    setCurrentPublicPage(newPage);
  };

  const handlePrivateChange = (event, newPage) => {
    setCurrentPrivatePage(newPage);
  };

  // PUBLIC COLLECTION SEARCH
  const filteredPublicCol = publicCollections.filter((pubCol) =>
    pubCol.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  // PRIVATE COLLECTION SEARCH
  const filteredPrivateCol = privateCollections.filter(
    (privCol) =>
      privCol.name.toLowerCase().includes(searchQueryPrivate.toLowerCase())
    // && privCol.ownerID === userId
  );

  const handleSearchChangePriv = (e) => {
    setSearchQueryPrivate(e.target.value);
  };

  const handleSearchSubmitPriv = (e) => {
    e.preventDefault();
  };

  const publicDisplay = filteredPublicCol.slice(
    (currentPublicPage - 1) * pageSize,
    currentPublicPage * pageSize
  );

  const privateDisplay = filteredPrivateCol.slice(
    (currentPrivatePage - 1) * pageSize,
    currentPrivatePage * pageSize
  );

  const [openPublic, setOpenPublic] = useState(false);
  const handleOpenPublic = () => setOpenPublic(true);
  const handleClosePublic = () => setOpenPublic(false);

  const [openPrivate, setOpenPrivate] = useState(false);
  const handleOpenPrivate = () => setOpenPrivate(true);
  const handleClosePrivate = () => setOpenPrivate(false);

  const [share, setShare] = useState(false);
  const handleShare = () => setShare(true);

  return (
    <Box className={classes.root}>
      <Navbar />
      <Box className={classes.centerContent}>
        <Paper
          square={false}
          elevation={0}
          variant="elevation"
          style={{
            backgroundColor: "black",
            width: 1300,
            height: "max-content",
            marginBottom: theme.spacing(4),
            borderRadius: 14,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 35,
              alignItems: "start",
            }}
          >
            <Typography
              className={classes.description}
              sx={{ fontFamily: "Raleway, sans-serif" }}
            >
              Add Collection
            </Typography>
            <TextField
              required
              variant="outlined"
              label="Enter new collection name"
              value={collectionName}
              onChange={handleCollectionNameChange}
              fullWidth
              margin="normal"
              InputLabelProps={{
                style: {
                  fontFamily: "Raleway, sans-serif",
                  fontSize: "0.9rem",
                  borderColor: "white",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.7rem",
                  },
                },
              }}
              InputProps={{
                style: {
                  color: "black",
                  backgroundColor: "white",
                  borderRadius: "9px",
                  fontFamily: "Raleway, sans-serif",
                  fontSize: "1rem",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.7rem",
                  },
                },
              }}
            />
            <TextField
              required
              variant="outlined"
              label="Enter a short description"
              value={collectionDescription}
              onChange={handleCollectionDescriptionChange}
              fullWidth
              margin="normal"
              InputLabelProps={{
                style: {
                  fontFamily: "Raleway, sans-serif",
                  fontSize: "0.9rem",
                  borderColor: "white",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.7rem",
                  },
                },
              }}
              InputProps={{
                style: {
                  color: "black",
                  backgroundColor: "white",
                  borderRadius: "9px",
                  fontFamily: "Raleway, sans-serif",
                  fontSize: "1rem",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.7rem",
                  },
                },
              }}
            />
            <FormControl>
              <RadioGroup
                row
                aria-label="collection-type"
                value={collectionType}
                onChange={handleCollectionTypeChange}
              >
                <FormControlLabel
                  value="private"
                  control={<Radio style={{ color: "#FFC629" }} />}
                  label={
                    <Typography
                      style={{
                        color: "white",
                        fontFamily: "Raleway, sans-serif",
                      }}
                    >
                      Private
                    </Typography>
                  }
                  labelPlacement="end"
                  onChange={() => console.log("private")}
                />
                <FormControlLabel
                  value="public"
                  control={<Radio style={{ color: "#FFC629" }} />}
                  label={
                    <Typography
                      style={{
                        color: "white",
                        fontFamily: "Raleway, sans-serif",
                      }}
                    >
                      Public
                    </Typography>
                  }
                  labelPlacement="end"
                  // checked={checked2}
                  onChange={() => console.log("public")}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Button
            onClick={handleCreateCollection}
            disabled={loading}
            style={{
              backgroundColor: "#FFC629",
              color: "#000000",
              borderRadius: "10px",
              padding: theme.spacing(1, 3),
              fontSize: "1rem",
              fontWeight: "bold",
              textTransform: "none",
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(4),
              fontFamily: "Raleway, sans-serif",
              "&:hover": {
                backgroundColor: "#FFC629",
                color: "#000000",
              },
              [theme.breakpoints.down("sm")]: {
                fontSize: "0.9rem", // font size for smaller screens
              },
            }}
          >
            {loading ? "Creating . . ." : "Create Collection"}
          </Button>
        </Paper>
        {/* Public Collections */}
        <Box className={classes.centerContent}>
          <Paper
            square={false}
            elevation={0}
            variant="elevation"
            style={{
              backgroundColor: "black",
              width: 1300,
              height: "max-content",
              marginBottom: theme.spacing(4),
              borderRadius: 14,
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 35,
              }}
            >
              <Typography
                className={classes.description}
                style={{ fontFamily: "Raleway, sans-serif" }}
              >
                Public Collections
              </Typography>
              <Box className={classes.searchUserlistBox}>
                <Paper
                  component="form"
                  className={classes.searchBox}
                  style={{ borderRadius: "8px" }}
                  onSubmit={handleSearchSubmit}
                >
                  <InputBase
                    style={{ fontFamily: "Raleway", fontSize: "1rem" }}
                    className={classes.searchInput}
                    placeholder="Search"
                    inputProps={{ "aria-label": "Search public collection" }}
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <IconButton
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchRoundedIcon />
                  </IconButton>
                </Paper>
                <Box className={classes.userList}>
                  <List>
                    {publicDisplay.map((collection) => (
                      <ListItem
                        key={collection.id}
                        className={classes.listItem}
                        divider
                      >
                        <ListItemText
                          primary={collection.name}
                          secondary={collection.description}
                          className={classes.listItemText}
                          primaryTypographyProps={{
                            style: {
                              fontFamily: "Raleway, sans-serif",
                              fontWeight: "bold",
                            },
                          }}
                          secondaryTypographyProps={{
                            style: {
                              fontFamily: "Raleway, sans-serif",
                              color: "#FFC629",
                            },
                          }}
                        />
                        {userRole !== "user" && (
                          <Box className={classes.iconBox}>
                            <IconButton
                              aria-label="delete"
                              style={{
                                backgroundColor: "#FFC629",
                                borderRadius: 5,
                                width: 25,
                                height: 25,
                              }}
                              onClick={() => {
                                openDeleteModal({
                                  id: collection.id,
                                  name: collection.name,
                                  isPublic: true,
                                });
                              }}
                            >
                              <DeleteOutlineOutlinedIcon
                                sx={{ color: "black", width: 20, height: 20 }}
                              />
                            </IconButton>
                            {collectionToDelete && (
                              <Modal
                                open={Boolean(collectionToDelete)}
                                onClose={closeDeleteModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box className={classes.modal}>
                                  <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                    style={{
                                      textAlign: "center",
                                      fontFamily: "Raleway",
                                      fontWeight: "bolder",
                                      color: "white",
                                      fontSize: "1.5rem",
                                    }}
                                  >
                                    Are you sure you want to delete{" "}
                                    {collectionToDelete.name}?
                                  </Typography>
                                  <Box
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                      marginTop: theme.spacing(3),
                                    }}
                                  >
                                    <Button
                                      disabled={loading}
                                      onClick={() => {
                                        handleDeleteCollection(
                                          collectionToDelete.id,
                                          collectionToDelete.name,
                                          collectionToDelete.isPublic
                                        );
                                      }}
                                      style={{
                                        backgroundColor: "#FFC629",
                                        color: "#000000",
                                        borderRadius: "10px",
                                        padding: theme.spacing(1, 3),
                                        fontSize: "1rem",
                                        fontWeight: "bold",
                                        textTransform: "none",
                                        fontFamily: "Raleway, sans-serif",
                                        "&:hover": {
                                          backgroundColor: "#FFC629",
                                          color: "#000000",
                                        },
                                        [theme.breakpoints.down("sm")]: {
                                          fontSize: "0.9rem", // font size for smaller screens
                                        },
                                      }}
                                    >
                                      {loading ? "Deleting . . ." : "Delete"}
                                    </Button>
                                    <Button
                                      onClick={closeDeleteModal}
                                      style={{
                                        backgroundColor: "#F5F5F5",
                                        color: "#000000",
                                        borderRadius: "10px",
                                        padding: theme.spacing(1, 3),
                                        fontSize: "1rem",
                                        fontWeight: "bold",
                                        textTransform: "none",
                                        fontFamily: "Raleway, sans-serif",
                                        "&:hover": {
                                          backgroundColor: "#FFC629",
                                          color: "#000000",
                                        },
                                        [theme.breakpoints.down("sm")]: {
                                          fontSize: "0.9rem",
                                        },
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </Box>
                                </Box>
                              </Modal>
                            )}
                          </Box>
                        )}
                      </ListItem>
                    ))}
                  </List>
                  {filteredPublicCol.length > pageSize && (
                    <Pagination
                      sx={{
                        display: "inline-flex",
                        backgroundColor: "#FFC629",
                        borderRadius: 1,
                        width: "fit-content",
                      }}
                      size="small"
                      shape="rounded"
                      variant="outined"
                      count={Math.ceil(filteredPublicCol.length / pageSize)}
                      page={currentPublicPage}
                      onChange={handlePublicChange}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>

        {/* Private Collections */}
        <Paper
          square={false}
          elevation={0}
          variant="elevation"
          style={{
            backgroundColor: "black",
            width: 1300,
            height: "max-content",
            borderRadius: 14,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 35,
            }}
          >
            <Typography
              className={classes.description}
              sx={{ fontFamily: "Raleway, sans-serif" }}
            >
              Private Collections
            </Typography>
            <Box className={classes.searchUserlistBox}>
              <Paper
                component="form"
                className={classes.searchBox}
                onSubmit={handleSearchSubmitPriv}
              >
                <InputBase
                  style={{ fontFamily: "Raleway" }}
                  className={classes.searchInput}
                  placeholder="Search"
                  inputProps={{ "aria-label": "Search private collection" }}
                  value={searchQueryPrivate}
                  onChange={handleSearchChangePriv}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchRoundedIcon />
                </IconButton>
              </Paper>
              <Box className={classes.userList}>
                <List>
                  {privateDisplay.map((collection) => (
                    <ListItem key={collection.id} className={classes.listItem}>
                      <ListItemText
                        primary={collection.name}
                        secondary={collection.description}
                        primaryTypographyProps={{
                          style: {
                            fontFamily: "Raleway, sans-serif",
                            fontWeight: "bold",
                          },
                        }}
                        secondaryTypographyProps={{
                          style: {
                            fontFamily: "Raleway, sans-serif",
                            color: "#FFC629",
                          },
                        }}
                      />
                      <Box className={classes.iconBox}>
                        {collection.ownerID === userId && (
                          <IconButton
                            aria-label="share"
                            style={{
                              backgroundColor: "#FFC629",
                              borderRadius: 5,
                              width: 25,
                              height: 25,
                            }}
                            onClick={() => openShareModal(collection)}
                          >
                            <ShareRoundedIcon
                              style={{ color: "black", width: 20, height: 20 }}
                            />
                          </IconButton>
                        )}
                        {collectionToShare && (
                          <Modal
                            open={Boolean(collectionToShare)}
                            onClose={closeShareModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box className={classes.modal}>
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Raleway",
                                  fontWeight: "bolder",
                                  color: "white",
                                  fontSize: "1.5rem",
                                  marginBottom: theme.spacing(2),
                                }}
                                InputProps
                              >
                                Sharing {collectionToShare.name}
                              </Typography>

                              <TextField
                                label="User email address"
                                type="email"
                                id="email"
                                value={shareEmail}
                                onChange={(e) => setShareEmail(e.target.value)}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                // style={{ marginBottom: theme.spacing(1) }}
                                InputLabelProps={{
                                  style: {
                                    fontFamily: "Raleway, sans-serif",
                                    fontSize: "0.9rem",
                                    [theme.breakpoints.down("sm")]: {
                                      fontSize: "0.7rem",
                                    },
                                  },
                                }}
                                InputProps={{
                                  style: {
                                    color: "black",
                                    backgroundColor: "white",
                                    borderRadius: "9px",
                                    fontFamily: "Raleway, sans-serif",
                                    fontSize: "0.9rem",
                                    [theme.breakpoints.down("sm")]: {
                                      fontSize: "0.7rem",
                                    },
                                  },
                                }}
                              />

                              <Typography
                                variant="subtitle1"
                                style={{
                                  marginTop: 20,
                                  color: "#FFFFFF",
                                  fontFamily: "Raleway",
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                }}
                              >
                                Shared With:
                              </Typography>
                              <Box
                                style={{
                                  maxHeight: "18vh",
                                  overflowY: "auto",
                                  overflowX: "hidden",
                                  padding: theme.spacing(1.5),
                                  boxShadow: 24,
                                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                                  borderRadius: "8px",
                                }}
                              >
                                {loading ? (
                                  <Typography
                                    style={{
                                      fontFamily: "Raleway",
                                      color: "#FFFFFF",
                                      fontSize: "0.8rem",
                                      alignItems: "center",
                                    }}
                                  >
                                    Loading...
                                  </Typography>
                                ) : sharedUsers.length > 0 ? (
                                  <List>
                                    {sharedUsers.map((email, index) => (
                                      <ListItem
                                        key={index}
                                        className={classes.listShare}
                                        style={{
                                          paddingTop: theme.spacing(0),
                                          paddingBottom: theme.spacing(0),
                                        }}
                                      >
                                        <ListItemText
                                          primary={email}
                                          primaryTypographyProps={{
                                            style: {
                                              fontFamily: "Raleway, sans-serif",
                                              fontSize: "0.9rem",
                                            },
                                          }}
                                        />
                                        <IconButton
                                          onClick={() =>
                                            handleUnshareUser(email)
                                          }
                                        >
                                          <RemoveCircleOutlineRoundedIcon
                                            fontSize="small"
                                            sx={{ color: "#797c7f" }}
                                          />
                                        </IconButton>
                                      </ListItem>
                                    ))}
                                  </List>
                                ) : (
                                  <Typography
                                    style={{
                                      fontFamily: "Raleway",
                                      color: "#FFFFFF",
                                      fontSize: "0.8rem",
                                      alignItems: "center",
                                    }}
                                  >
                                    No users have access to this collection yet.
                                  </Typography>
                                )}
                              </Box>
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  marginTop: theme.spacing(3),
                                }}
                              >
                                <Button
                                  style={{
                                    backgroundColor: "#FFC629",
                                    color: "#000000",
                                    borderRadius: "10px",
                                    padding: theme.spacing(1, 3),
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    fontFamily: "Raleway, sans-serif",
                                    "&:hover": {
                                      backgroundColor: "#FFC629",
                                      color: "#000000",
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                      fontSize: "0.9rem",
                                    },
                                  }}
                                  onClick={handleShareCollection}
                                >
                                  {loading ? "Sharing . . ." : "Share"}
                                </Button>
                                <Button
                                  onClick={closeShareModal}
                                  style={{
                                    backgroundColor: "#F5F5F5",
                                    color: "#000000",
                                    borderRadius: "10px",
                                    padding: theme.spacing(1, 3),
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    fontFamily: "Raleway, sans-serif",
                                    "&:hover": {
                                      backgroundColor: "#E0E0E0",
                                      color: "#000000",
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                      fontSize: "0.9rem",
                                    },
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Box>
                            </Box>
                          </Modal>
                        )}

                        {collection.ownerID === userId && (
                          <IconButton
                            aria-label="delete"
                            style={{
                              backgroundColor: "#FFC629",
                              borderRadius: 5,
                              width: 25,
                              height: 25,
                            }}
                            onClick={() => {
                              openDeleteModal({
                                id: collection.id,
                                name: collection.name,
                                isPublic: false,
                              });
                            }}
                          >
                            <DeleteOutlineOutlinedIcon
                              style={{ color: "black", width: 20, height: 20 }}
                            />
                          </IconButton>
                        )}
                        {collectionToDelete && (
                          <Modal
                            open={Boolean(collectionToDelete)}
                            onClose={closeDeleteModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box className={classes.modal}>
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Raleway",
                                  fontWeight: "bolder",
                                  color: "white",
                                  fontSize: "1.5rem",
                                }}
                              >
                                Are you sure you want to delete{" "}
                                {collectionToDelete.name}?
                              </Typography>
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  marginTop: theme.spacing(3),
                                }}
                              >
                                <Button
                                  disabled={loading}
                                  onClick={() => {
                                    handleDeleteCollection(
                                      collectionToDelete.id,
                                      collectionToDelete.name,
                                      collectionToDelete.isPublic
                                    );
                                  }}
                                  style={{
                                    backgroundColor: "#FFC629",
                                    color: "#000000",
                                    borderRadius: "10px",
                                    padding: theme.spacing(1, 3),
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    fontFamily: "Raleway, sans-serif",
                                    "&:hover": {
                                      backgroundColor: "#FFC629",
                                      color: "#000000",
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                      fontSize: "0.9rem",
                                    },
                                  }}
                                >
                                  {loading ? "Deleting . . ." : "Delete"}
                                </Button>
                                <Button
                                  onClick={closeDeleteModal}
                                  style={{
                                    backgroundColor: "#F5F5F5",
                                    color: "#000000",
                                    borderRadius: "10px",
                                    padding: theme.spacing(1, 3),
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    fontFamily: "Raleway, sans-serif",
                                    "&:hover": {
                                      backgroundColor: "#E0E0E0",
                                      color: "#000000",
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                      fontSize: "0.9rem",
                                    },
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Box>
                            </Box>
                          </Modal>
                        )}
                      </Box>
                    </ListItem>
                  ))}
                </List>
                {filteredPrivateCol.length > pageSize && (
                  <Pagination
                    sx={{
                      display: "inline-flex",
                      backgroundColor: "#FFC629",
                      borderRadius: 1,
                      width: "fit-content",
                    }}
                    size="small"
                    shape="rounded"
                    variant="outined"
                    count={Math.ceil(filteredPrivateCol.length / pageSize)}
                    page={currentPrivatePage}
                    onChange={handlePrivateChange}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Paper>
        <SnackbarAlert
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={handleCloseSnackbar}
        />
      </Box>
      <Footer />
    </Box>
  );
}
export default CollectionManagement;
