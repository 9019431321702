import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  InputBase,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Pagination,
  useTheme,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../components/Navigation";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "@mui/material/Modal";
import API_ENDPOINTS from "../utilities/APIRoutes";
import SnackbarAlert from "../components/SnackbarAlert";
import Footer from "../components/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    fontFamily: "Raleway",
    padding: theme.spacing(0.2),
  },
  description: {
    color: "#ffffff",
    fontSize: "1.4rem",
    fontFamily: "Raleway, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem", // font size for smaller screens
    },
  },
  centerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    fontFamily: "Raleway, sans-serif",
    padding: theme.spacing(10),
  },
  userList: {
    // padding: "1px 38px",
    marginTop: "1em",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#303030",
    marginBottom: "1em",
    borderRadius: 8,
  },
  listItemText: {
    fontFamily: "Raleway, sans-serif",
    color: "white",
    fontSize: "2em",
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    width: 300,
    height: 35,
    justifyContent: "space-between",
    backgroundColor: "#f5f5f5",
    borderRadius: 8,
    padding: "2px 12px",
  },
  iconBox: {
    display: "flex",
    width: "6%",
    justifyContent: "space-around",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#202020",
    borderRadius: "9px",
    padding: theme.spacing(5),
  },
  button: {
    backgroundColor: "#FFC629",
    color: "#000000",
    borderRadius: "10px",
    padding: theme.spacing(1, 3),
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "none",
    marginTop: theme.spacing(2),
    fontFamily: "Raleway, sans-serif",
    "&:hover": {
      backgroundColor: "#FFC629",
      color: "#000000",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem", // font size for smaller screens
    },
  },
  footerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    backgroundColor: "#FFC629",
    padding: theme.spacing(2),
  },
  footer: {
    color: "#747171",
    fontSize: "0.8rem",
    fontFamily: "Raleway, sans-serif",
  },
}));

function UserManagement() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const [userToDelete, setUserToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 4; // Number of people per page
  const handleOpen = (userId) => {
    setUserToDelete(userId);
    setOpen(true);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const UserToken = localStorage.getItem("userToken");
  const AuthToken = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchUsers = async () => {
      if (!UserToken) {
        console.error("No user token found in local storage");
        return;
      }

      try {
        const response = await fetch(API_ENDPOINTS.ListUser, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "User-Token": UserToken,
            Authorization: AuthToken,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }

        const data = await response.json();
        setUsers(data.message);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (location.state?.success) {
      setSnackbarMessage(location.state.message);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    }

    fetchUsers();
  }, [location.state]);

  const deleteUser = async (userId) => {
    setLoading(true);
    try {
      const response = await fetch(API_ENDPOINTS.DeleteUser, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "User-Token": UserToken,
          Authorization: AuthToken,
        },
        body: JSON.stringify({ user_id: userId }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (
        result.message === "user successfully deleted from Users Table" ||
        result.error === false
      ) {
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
        handleClose();

        setSnackbarMessage("Deleted successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        console.error("Failed to delete user:", result.message);
        setSnackbarMessage("Failed to delete user!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Failed to delete user:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  const handleEditClick = (user_id) => {
    localStorage.setItem("userId", user_id);
    navigate("edit-user");
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const usersToDisplay = filteredUsers.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <Box className={classes.root}>
      <Navbar />
      <Box className={classes.centerContent}>
        <Paper
          square={false}
          elevation={0}
          variant="elevation"
          style={{
            backgroundColor: "black",
            width: 1300,
            height: "max-content",
            padding: 35,
            borderRadius: 14,
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginRight: theme.spacing(3.5),
            }}
          >
            <Typography
              className={classes.description}
              style={{
                alignSelf: "flex-start",
                color: "#ffffff",
                fontSize: "1.2rem",
                fontWeight: "bold",
                fontFamily: "Raleway, sans-serif",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "1rem", // font size for smaller screens
                },
              }}
            >
              User management
            </Typography>
            <IconButton onClick={() => navigate("add-user")}>
              <AddBoxRoundedIcon
                fontSize="large"
                style={{ color: "#FFC629" }}
              />
            </IconButton>
          </Box>
          <Box>
            <Paper
              component="form"
              className={classes.searchBox}
              style={{ borderRadius: "8px" }}
              onSubmit={handleSearchSubmit}
            >
              <InputBase
                className={classes.searchInput}
                placeholder="Search Users"
                inputProps={{ "aria-label": "search user" }}
                style={{ fontFamily: "Raleway", fontSize: "1rem" }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchRoundedIcon />
              </IconButton>
            </Paper>
            <Box className={classes.userList}>
              <List>
                {usersToDisplay.map((user) => (
                  <ListItem key={user.id} className={classes.listItem}>
                    <ListItemText
                      primary={user.name}
                      style={{ color: "white", fontFamily: "Raleway, sans-serif" }}
                      className={classes.listItemText}
                    />
                    <Box className={classes.iconBox}>
                      <IconButton
                        // edge="end"
                        type="button"
                        aria-label="edit"
                        sx={{
                          backgroundColor: "#FFC629",
                          borderRadius: 1,
                          width: 25,
                          height: 25,
                        }}
                        onClick={() => handleEditClick(user.id)}
                      >
                        <EditIcon
                          sx={{ color: "black", width: 20, height: 20 }}
                        />
                      </IconButton>
                      <IconButton
                        // edge="end"
                        aria-label="delete"
                        sx={{
                          backgroundColor: "#FFC629",
                          borderRadius: 1,
                          width: 25,
                          height: 25,
                        }}
                        onClick={() => handleOpen(user.id)}
                      >
                        <DeleteOutlineOutlinedIcon
                          sx={{ color: "black", width: 20, height: 20 }}
                        />
                      </IconButton>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box className={classes.modal}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            style={{
                              textAlign: "center",
                              fontFamily: "Raleway",
                              fontWeight: "bolder",
                              color: "white",
                              fontSize: "1.5rem",
                            }}
                          >
                            Are you sure you want to delete this user?
                          </Typography>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              marginTop: theme.spacing(3),
                            }}
                          >
                            <Button
                              disabled={loading}
                              onClick={() => deleteUser(userToDelete)}
                              style={{
                                backgroundColor: "#FFC629",
                                color: "#000000",
                                borderRadius: "10px",
                                padding: theme.spacing(1, 3),
                                fontSize: "1rem",
                                fontWeight: "bold",
                                textTransform: "none",
                                fontFamily: "Raleway, sans-serif",
                                "&:hover": {
                                  backgroundColor: "#FFC629",
                                  color: "#000000",
                                },
                                [theme.breakpoints.down("sm")]: {
                                  fontSize: "0.9rem",
                                },
                              }}
                            >
                              {loading ? "Deleting" : "Delete"}
                            </Button>
                            <Button
                              onClick={() =>
                                (window.location.href = "/user-management")
                              }
                              style={{
                                backgroundColor: "#F5F5F5",
                                color: "#000000",
                                borderRadius: "10px",
                                padding: theme.spacing(1, 3),
                                fontSize: "1rem",
                                fontWeight: "bold",
                                textTransform: "none",
                                fontFamily: "Raleway, sans-serif",
                                "&:hover": {
                                  backgroundColor: "#FFC629",
                                  color: "#000000",
                                },
                                [theme.breakpoints.down("sm")]: {
                                  fontSize: "0.9rem", // font size for smaller screens
                                },
                              }}
                            >
                              Cancel
                            </Button>
                          </Box>
                        </Box>
                      </Modal>
                    </Box>
                  </ListItem>
                ))}
              </List>
              {filteredUsers.length > pageSize && (
                <Pagination
                  sx={{
                    display: "inline-flex",
                    backgroundColor: "#FFC629",
                    borderRadius: 1,
                    width: "fit-content",
                    marginBottom: theme.spacing(3),
                  }}
                  size="small"
                  variant="outined"
                  count={Math.ceil(filteredUsers.length / pageSize)}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              )}
            </Box>
          </Box>
        </Paper>
        <SnackbarAlert
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={handleCloseSnackbar}
        />
      </Box>
      <Footer />
    </Box>
  );
}

export default UserManagement;
